import React from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import SuggestionsSideBar from "../../app-components/SuggestionsSideBar";

const RequestHistory = () => {
  return (
    <AppLayout>
      <main className="sm:py-10">
        <div className="grid grid-cols-12 gap-8 justify-between w-full sm:w-11/12 mx-auto">
          {/* <!-- COlumn 3 --> */}
          <SuggestionsSideBar />
          {/* <!-- COlumn 7 --> */}
          <div className="col-span-12 sm:col-span-8 md:col-span-8 lg:col-span-7 bg-white rounded-10px py-10 px-7">
            {/* <!-- ROW --> */}
            <p className="sm:hidden text-sm mb-8 font-semibold">
              <a href="#!" className="text-honeConnectPurple">
                <i className="fa-solid fa-chevron-left"></i> Back
              </a>
            </p>
            {/* <!-- ROW  --> */}
            <div>
              <p className="text-lg font-medium text-honeConnectBlack mb-5">
                Filter request history by date
              </p>
            </div>
            {/* <!-- ROW --> */}
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-12 mb-8">
              <div className="flex flex-col gap-1">
                <p className="text-honeConnectGray text-sm font-medium">
                  Request start date
                </p>
                <input
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-10px focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="DD-MM-YYYY"
                />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-honeConnectGray text-sm font-medium">
                  Request end date
                </p>
                <input
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-10px focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="DD-MM-YYYY"
                />
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="flex flex-col gap-1 mb-8">
              <div className="flex justify-between text-honeConnectGray text-sm font-medium">
                <p>Filter by open request</p>
                <p>Filter by closed request</p>
              </div>
              <input
                className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-10px focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                placeholder="Open/Close"
              />
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-honeConnectGray50 border border-solid border-honeConnectGray200 rounded-10px p-3.5 mb-5">
              <p className="text-sm font-bold text-honeConnectBlack mb-1">
                You have 12 responses
              </p>
              <p className="text-xs text-honeConnectGray mb-2">06-07-2024</p>
              <div className="text-right">
                <button className="text-sm py-2 px-4 border border-solid bg-honeConnectPurple font-bold text-white rounded-[5px] text-right">
                  View All
                </button>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-[#FFF6ED] border border-solid border-honeConnectOrange rounded-10px p-3.5">
              <p className="text-sm font-bold text-honeConnectOrange mb-1">
                Closed Request
              </p>
              <p className="text-xs text-honeConnectOrange mb-2">05-12-2024</p>
              <p className="text-xs text-honeConnectOrange">
                15 responses completed
              </p>
              <div className="text-right">
                <button className="text-sm py-2 px-4 border border-solid bg-[#34C759] font-bold text-white rounded-[5px] text-right">
                  Reopen
                </button>
              </div>
            </div>
          </div>
          {/* <!-- Column 2 --> */}
          <AdsBanner></AdsBanner>
        </div>
      </main>
    </AppLayout>
    // <h1>Returning Request History page</h1>;
  );
};

export default RequestHistory;
