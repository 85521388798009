import {useQuery } from '@tanstack/react-query';
import apiClient  from '../../ApiClient';

const getInterest = async () => {
  const res = await apiClient.get("interest-prespecified");
  return res.data;
};

export function useInterest() {
  return useQuery({
    queryKey: ['all_interest'],
    queryFn: getInterest,
  });
}