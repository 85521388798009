// login component
import React, { FormEvent, useEffect, useState } from "react";
import AuthLayout from "../../app-layouts/AuthLayout";
import {
  RegisterStepTwoSchema,
  RegisterStepTwoType,
} from "../../app-schemas/auth.schema";
import apiClient from "../../api-services/ApiClient";
import { inputStyle } from "../../app-util/helpers";
import { Checkbox } from "../../app-components/CheckboxComponent";
import Preloader from "../../app-components/preloader/Preloader";
import Alert from "../../app-components/alert/Alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { successAlert } from "../../app-util/SweetAlert";
import { useNavigate } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { storeUser } from "../../api-services/process";

const RegisterStepTwo = () => {
  const navigate = useNavigate();

  const [countries, setCountries] = useState<
    {
      name: string;
      nationality: string;
      id: any;
    }[]
  >([]);
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>("");
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState("");

  // component-state
  const [registrationData, setRegistrationData] = useState<
    Partial<RegisterStepTwoType>
  >({});

  // use-state-form-errors
  const [errors, setErrors] = useState<
    Partial<Record<keyof RegisterStepTwoType, string>>
  >({});

  useEffect(() => {
    handleCountries();
  }, []);

  useEffect(() => {
    if (registrationData.country_id)
      handleStates(Number(registrationData.country_id) ?? 0);
  }, [registrationData.country_id]);

  useEffect(() => {
    if (registrationData.state_id)
      handleCities(Number(registrationData.state_id) ?? 0);
  }, [registrationData.state_id]);

  // handle-input-change
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any
    >
  ) => {
    const { name, value } = e.target;
    setRegistrationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value: string) => {
    setRegistrationData((prevState) => ({
      ...prevState,
      phone_number: value,
    }));
  };

  // handle-checkbox-change
  const handleCheckBox = (name: string, status?: boolean) => {
    setRegistrationData((prevState) => ({
      ...prevState,
      [name]: status,
    }));
  };

  const handleCountries = async () => {
    setPageLoading(true);
    setAlertMessage("");
    try {
      const res: any = await apiClient.get("countries");
      setCountries(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setAlertMessage("Unable to fetch countries");
    } finally {
      setPageLoading(false);
    }
  };

  const handleStates = async (country: number) => {
    setPageLoading(true);
    setAlertMessage("");
    try {
      const res: any = await apiClient.get(`countries/${country}/states`);
      setStates(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setAlertMessage("Unable to fetch states");
    } finally {
      setPageLoading(false);
    }
  };

  const handleCities = async (state: number) => {
    setPageLoading(true);
    setAlertMessage("");
    try {
      const res: any = await apiClient.get(`states/${state}/cities`);
      setCities(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setAlertMessage("Unable to fetch countries");
    } finally {
      setPageLoading(false);
    }
  };

  // handle-login-submit
  const handleSignUpStepTwo = async (e: FormEvent) => {
    e.preventDefault();
    setErrors({});
    setServerError("");

    const result = RegisterStepTwoSchema.safeParse(registrationData);
    if (result.success) {
      try {
        setLoading(true);
        const res = await apiClient.post("register-step-two", result.data);
        successAlert();
        if (res.data?.user) storeUser(res.data?.user);

        navigate(SITELINKS.REGISTER_STEP_THREE);
      } catch (error: any) {
        console.log(error);
        setServerError(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    } else {
      const formattedErrors: Partial<
        Record<keyof RegisterStepTwoType, string>
      > = {};

      result.error.errors.forEach((err) => {
        if (err.path.length > 0) {
          const key = err.path[0] as keyof RegisterStepTwoType;
          formattedErrors[key] = err.message;
        }
      });

      setErrors(formattedErrors);
      console.log(formattedErrors);
    }
  };

  return (
    <AuthLayout>
      <section className="py-3 px-0 lg:col-span-3">
        {/* return to website link */}
        <div className="text-sm font-semibold flex justify-end">
          <p className="uppercase text-honeConnectGray hidden sm:block">
            Step 2/3
          </p>
        </div>

        {/* form section */}
        <div className="pt-8 lg:pt-4 px-5 lg:px-5 xl:px-5 2xl:px-6 text-honeConnectGray">
          <div>
            <h4 className="text-xl text-honeConnectBlack font-bold mb-2.5">
              Add Location
            </h4>
            <p className="text-xs mb-8 font-medium">
              To keep connected kindly update your <br />
              DOB and location in the below fileds.
            </p>
          </div>

          <form
            onSubmit={handleSignUpStepTwo}
            autoComplete="off"
            autoCorrect="off"
            className="space-y-2"
          >
            {/* User ID */}
            <div className="flex flex-col w-full">
              <label htmlFor="userID" className="mb-2 text-sm font-medium">
                User ID
              </label>
              <input
                value={registrationData?.username
                  ?.replace(/\s+/g, "")
                  .toLocaleLowerCase()}
                onChange={handleInputChange}
                type="text"
                name="username"
                placeholder="Enter your preferred user ID or profile name"
                className={inputStyle}
              />
              <span className="text-red-600 text-xs">{errors.username}</span>
            </div>

            {/* Phone Number */}
            <div className="flex flex-col w-full">
              <label htmlFor="phone" className="mb-2 text-sm font-medium">
                Phone Number
              </label>
              <PhoneInput
                country="ng"
                value={registrationData.phone_number as string}
                onChange={handlePhoneChange}
                inputClass={`${inputStyle} text-xs`}
                inputProps={{ autoComplete: "off" }}
              />
              <span className="text-red-600 text-xs">
                {errors.phone_number}
              </span>
            </div>

            {/* Date of Birth and Gender */}
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="flex flex-col w-full">
                <label htmlFor="dob" className="mb-2 text-sm font-medium">
                  Date of Birth
                </label>
                <input
                  value={registrationData.DOB}
                  onChange={handleInputChange}
                  type="date"
                  name="DOB"
                  className={inputStyle}
                />
                <span className="text-red-600 text-xs">{errors.DOB}</span>
              </div>

              <div className="flex flex-col w-full">
                <label htmlFor="gender" className="mb-2 text-sm font-medium">
                  Gender
                </label>
                <select
                  value={registrationData.gender}
                  name="gender"
                  onChange={handleInputChange}
                  className={inputStyle}
                >
                  <option value="">Select Gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHERS">Not Specified</option>
                </select>
                <span className="text-red-600 text-xs">{errors.gender}</span>
              </div>
            </div>

            {/* Country and State */}
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="flex flex-col w-full">
                <label htmlFor="country" className="mb-2 text-sm font-medium">
                  Country
                </label>
                <select
                  value={registrationData.country_id ?? ""}
                  name="country_id"
                  onChange={handleInputChange}
                  className={inputStyle}
                >
                  <option value="">Select Country</option>
                  {countries?.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="text-red-600 text-xs">
                  {errors.country_id}
                </span>
              </div>

              <div className="flex flex-col w-full">
                <label htmlFor="state" className="mb-2 text-sm font-medium">
                  State
                </label>
                <select
                  value={registrationData.state_id ?? ""}
                  name="state_id"
                  onChange={handleInputChange}
                  className={inputStyle}
                >
                  <option value="">Select State</option>
                  {states?.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="text-red-600 text-xs">{errors.state_id}</span>
              </div>
            </div>

            {/* City and Area */}
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="flex flex-col w-full">
                <label htmlFor="city" className="mb-2 text-sm font-medium">
                  City
                </label>
                <select
                  value={registrationData.city_id ?? ""}
                  name="city_id"
                  onChange={handleInputChange}
                  className={inputStyle}
                >
                  <option value="">Select Closest City</option>
                  {cities?.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <span className="text-red-600 text-xs">{errors.city_id}</span>
              </div>

              <div className="flex flex-col w-full">
                <label htmlFor="area" className="mb-2 text-sm font-medium">
                  Area
                </label>
                <input
                  value={registrationData.area}
                  name="area"
                  type="text"
                  placeholder="Area"
                  className={inputStyle}
                  onChange={handleInputChange}
                />
                <span className="text-red-600 text-xs">{errors.area}</span>
              </div>
            </div>

            {/* Terms and Conditions */}
            <div className="flex items-center gap-2">
              <Checkbox
                checked={registrationData.isAgreedTerms}
                onChange={() =>
                  handleCheckBox(
                    "isAgreedTerms",
                    !registrationData.isAgreedTerms
                  )
                }
              />
              <p className="text-xs">
                I agree to the{" "}
                <span className="text-honeConnectOrange">
                  Terms & Conditions
                </span>{" "}
                and
                <span className="text-honeConnectOrange"> Privacy Policy.</span>
              </p>
            </div>

            <span className="text-red-600 text-xs">{errors.isAgreedTerms}</span>

            {/* Error Message */}
            <div>
              {serverError && (
                <p className="text-red-600 text-center text-xs mt-5">
                  {serverError}
                </p>
              )}
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="disabled:bg-gray-600 w-full p-3 rounded-lg bg-honeConnectPurple text-white"
                disabled={loading}
              >
                {loading ? "Loading..." : "Continue"}
              </button>
            </div>
          </form>
        </div>
      </section>

      <Preloader loading={pageLoading} />
      <Alert message={alertMessage} />
    </AuthLayout>
  );
};

export default RegisterStepTwo;
