// login component
import React, { useState, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../app-layouts/AuthLayout";
import { LuChevronLeft } from "react-icons/lu";
import { SITELINKS } from "../../app-routes/links";
import apiClient from "../../api-services/ApiClient";
import { successAlert } from "../../app-util/SweetAlert";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [serverError, setServerError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailErrors, setEmailErrors] = useState<string>("");
  const [pageLoading, setPageLoading] = useState(false);

  // handle-login-submit
  const handleResetPassword = async (e: FormEvent) => {
    e.preventDefault();
    setEmailErrors("");
    setServerError("");

    if (email) {
      try {
        setPageLoading(true);
        const res = await apiClient.post("forgot-password", { email });

        successAlert();
        if (res.data.status) {
          navigate(SITELINKS.VERIFICATION, {
            state: { fromResetPassword: true, email: email },
          });
        }
      } catch (error: any) {
        setServerError(JSON.stringify(error));
      } finally {
        setPageLoading(false);
      }
    } else {
      setEmailErrors("Email address is required");
    }
  };

  return (
    <AuthLayout>
      <section className="py-3 px-0 lg:col-span-3">
        {/* return to website link */}
        <div className="text-sm font-semibold">
          <Link to={SITELINKS.LOGIN} className="text-honeConnectPurple flex">
            <LuChevronLeft className="text-xl mt-[1px]" /> <span>Back</span>
          </Link>
        </div>

        {/* form section */}
        <div className="flex lg:items-center pt-6 lg:pt-20 pb-0 px-3 md:pr-52 md:pl-28 lg:px-28 xl:px-28 2xl:px-60 text-honeConnectGray">
          <div>
            <h4 className="text-2xl text-honeConnectBlack font-bold mb-2.5">
              Reset Password
            </h4>
            <p className="text-xs mb-4 font-medium text-honeConnectOrange pr-10">
              Enter your email address and we will send you A 6-digit code to
              get back into your account.
            </p>

            <form onSubmit={handleResetPassword}>
              <div className="flex flex-col text-sm font-medium mb-5">
                <input
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-600 p-3 px-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal text-xs"
                  placeholder="Enter your email address"
                  type="email"
                  value={email}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                  }}
                />
                <span className="text-red-600 text-[10px]">{emailErrors}</span>
              </div>

              <p className="text-red-600 text-[12px] text-center mb-4">
                {serverError}
              </p>

              <button
                disabled={pageLoading}
                type="submit"
                className="disabled:bg-gray-600 bg-honeConnectPurple text-white w-full p-3 rounded-lg border-0 text-sm"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};

export default ForgotPassword;
