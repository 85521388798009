import AdsBanner from "../../app-layouts/AdsBanner";
import AppLayout from "../../app-layouts/AppLayout";
import HomePageSideNav from "../../app-components/HomeSideNav";
import { Link, useNavigate } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useEffect, useMemo } from "react";
import { APP_STORAGE } from "../../api-services/process";
import Preloader from "../../app-components/preloader/Preloader";
import { errorAlert, successAlert } from "../../app-util/SweetAlert";
import apiClient from "../../api-services/ApiClient";
import { useState } from "react";
import { LuUsers2 } from "react-icons/lu";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { Loading } from "../../assets/img";
import { TERipple } from "tw-elements-react";
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { useInterest } from "../../api-services/api-query/Interest/useInterests";
import { getUsersRequests } from "../../api-services/api-query/connection-requests/useUserRequests";

const MyRequests = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView();
  const [laoding, setLoading] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [showModal, setShowModal] = useState(false);
  const interestData = useInterest();
  const [interestId, setInterestId] = useState<number>(1);

  const filterInterest = (data: any) => {
    if (data) {
      const uniqueInterests = new Map();

      data.forEach((item: any) => {
        const interest = item.interest;
        uniqueInterests.set(interest.id, interest);
      });
      const interestsArray = Array.from(uniqueInterests.values());

      return interestsArray;
    }

    return [];
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ["my_requests"],
      queryFn: getUsersRequests,
      initialPageParam: 1,
      getNextPageParam: (data) =>
        data.currentPage < data.totalPages ? data.currentPage + 1 : undefined,
    });

  const getImageSrc = (post: any) => {
    if (post?.caption_image)
      return `${APP_STORAGE}gallery_pictures/${post.caption_image}`;
    else if (post?.user?.profile_pix_link)
      return `${APP_STORAGE}${post.user.profile_pix_link}`;
    return "img/PrivateAccount.png";
  };

  const handleConnect = async (post: any) => {
    const data = {
      connection_request_id: post?.uuid,
    };

    try {
      setLoading(true);
      const res = await apiClient.post("connection-requests-responses", data);
      successAlert();

      if (res.data.status) {
        navigate(SITELINKS.MY_RESPONSES);
      }
    } catch (error: any) {
      errorAlert(JSON.stringify(error), 20000);
      navigate(SITELINKS.SUBSCRIPTIONS, {
        state: { lastRequestData: data },
      });
    } finally {
      setLoading(false);
    }
  };

  const connectionComponent = useMemo(() => {
    if (!data || data.pages.length === 0) {
      return (
        <div className="text-center text-gray-500 mt-[25vh]">
          <p className="mb-5">No history available</p>
          <Link className="bg-honeConnectPurple py-2 px-5 border text-white shadow rounded-lg" to={SITELINKS.MAKE_REQUEST}>Make a request</Link>
        </div>
      );
    }

    return data.pages.flatMap((page) =>
      page.data.map((post: any, index: number) => (
        <div
          key={post.uuid}
          ref={index === 5 ? ref : undefined}
          className="w-full flex flex-col gap-1 bg-white lg:rounded-10px sm:mb-0 p-2 py-3"
        >
          <div className="overflow-hidden max-h-[410px] min-h-[200px] w-full rounded-xl border">
            <img
              src={getImageSrc(post)}
              alt="Display"
              className="w-full rounded-xl bg-gray-100"
            />
          </div>

          <div className="flex justify-between gap-2 items-center w-full">
            <div className="flex flex-col">
              <div className="flex items-center">
                {/* <div className="text-xl font-semibold capitalize">
                  {post?.user?.username}
                </div> */}

                {/* {post?.user?.badge && (
                  <img
                    src={getBadgeSrc(post.user.badge)}
                    alt={`${post.user.badge} Badge`}
                    className="w-8 h-8"
                  />
                )} */}
              </div>

              <p className="font-normal text-sm">
                {post?.city?.name}, {post?.state?.name}, {post?.country?.name}
              </p>
            </div>

            <div>
              <TERipple rippleColor="#aaa">
                <button
                  className={`${
                    post?.status !== "accepted"
                      ? "bg-honeConnectOrange"
                      : "bg-[#6A6873]"
                  } py-2 px-5 font-semibold text-sm text-white rounded-[5px]`}
                  onClick={() => {
                    handleConnect(post);
                  }}
                >
                  Update
                </button>
              </TERipple>
            </div>
          </div>

          <div className="font-medium text-xs text-honeConnectGray w-full">
            {post?.prespecified_text?.content}
          </div>

          {/* connect interest */}
          <div className="flex gap-2 w-full">
            <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-normal text-honeConnectGray rounded-sm">
              {post?.interest?.interest}
            </p>
          </div>
        </div>
      ))
    );
    // eslint-disable-next-line
  }, [data, ref]);

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const direction = prevScrollPos > currentScrollPos ? "up" : "down";
      if (currentScrollPos > 60) {
        setScrollDirection(direction);
      } else {
        setScrollDirection(null);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  if (isLoading) {
    return (
      <AppLayout title="Home">
        <Preloader loading={true} />
      </AppLayout>
    );
  }

  return (
    <AppLayout title="Welcome">
      <div className="grid grid-cols-12 gap-8 justify-between">
        <HomePageSideNav />

        <div className="flex bg-hc_F4EBFA h-[100vh] border-b border-gray-100 col-span-12 sm:col-span-7 w-full">
          <div className="flex flex-col w-full">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-28 mb-20 w-full">
              <div
                className={`shadow flex w-full bg-white border-b z-10 fixed transition-all duration-500 top-0 ${
                  scrollDirection === null
                    ? "translate-y-[57px]"
                    : scrollDirection === "up"
                    ? "translate-y-0 top-[57px]"
                    : "-translate-y-full"
                }`}
              >
                <TERipple rippleColor="#aaa">
                  <button className="py-3 lg:relative lg:rounded-10px font-medium lg:text-lg lg:p-3 px-3 flex gap-3 items-center">
                    <LuUsers2
                      style={{ fill: "#d4b1e6" }}
                      className="fa-solid fa-users text-gray-600 text-2xl"
                    />
                    <p className="text-honeConnectBlack">
                      {`${data?.pages[0]?.totalItems ?? "0"} Request${
                        data?.pages[0]?.totalItems > 1 ? "s" : ""
                      }`}
                    </p>
                  </button>
                </TERipple>

                <TERipple rippleColor="#aaa">
                  <button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="py-3 lg:text-lg lg:p-3 px-3 flex gap-2 items-center pr-2 border-l font-medium "
                  >
                    <TbAdjustmentsHorizontal
                      style={{ fill: "#d4b1e6" }}
                      className="text-2xl text-gray-600"
                    />
                    <span className="text-honeConnectBlack">All filters</span>
                  </button>
                </TERipple>
              </div>

              {connectionComponent}

              {isFetchingNextPage && (
                <div className="flex py-5 pb-16 justify-center">
                  <img
                    src={Loading}
                    alt="loader"
                    className="h-[20px] w-[20px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <AdsBanner />
      </div>

      {/* <!-- Modal --> */}
      <TEModal show={showModal}>
        <TEModalDialog className="rounded-0">
          <TEModalContent className="h-[100vh] overflow-scroll rounded-t-none">
            <TEModalHeader className="bg-honeConnectPurple text-white rounded-t-none">
              {/* <!--Modal title--> */}
              <h5 className="text-md font-normal leading-normal text-white">
                Filter Results
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            {/* <!--Modal body--> */}
            <TEModalBody>
              <div className="px-3 py-5">
                <p className="text-honeConnectBlack text-xs font-medium mb-3">
                  Filter by Interest
                </p>

                <div className="grid grid-cols-2 xl:grid-cols-2 gap-y-5 items-center mt-2 mb-3">
                  {filterInterest(interestData.data).map((item: any) => {
                    return (
                      <label
                        key={item.id}
                        className="items-center xl:col-span-1 custom-checkbox"
                      >
                        <input
                          type="checkbox"
                          name=""
                          id="col1"
                          className="h-5 w-5 accent-purple"
                          onChange={(e) => {
                            setInterestId(item.id);
                          }}
                          checked={item.id === interestId}
                        />
                        <span className="checkmark"></span>
                        <span className="text-sm text-honeConnectGray font-medium">
                          {item.interest}
                        </span>
                      </label>
                    );
                  })}
                </div>
              </div>

              {/* filter by gender */}
              <div className="px-3 border-t py-5">
                <p className="text-honeConnectBlack text-xs font-medium mb-3">
                  Filter by Status
                </p>

                <div className="flex gap-7">
                  <label className="items-center xl:col-span-1 custom-checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id="col1"
                      className="h-5 w-5 accent-purple"
                    />
                    <span className="checkmark"></span>
                    <span className="text-sm text-honeConnectGray font-medium">
                      Active
                    </span>
                  </label>

                  <label className="items-center xl:col-span-1 custom-checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id="col1"
                      className="h-5 w-5 accent-purple"
                    />
                    <span className="checkmark"></span>
                    <span className="text-sm text-honeConnectGray font-medium">
                      Closed
                    </span>
                  </label>
                </div>
              </div>

              {/* filter by date */}
              <div className="px-3 py-5 border-t">
                <p className="text-honeConnectBlack text-xs font-semibold mb-3">
                  Filter by Date
                </p>

                <div className="grid grid-cols-2 xl:grid-cols-2 gap-y-5">
                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray font-medium">
                        All time
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray font-medium">
                        Last 7 days
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray font-medium">
                        Last 3 days
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray font-medium">
                        Last 24 hours
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray font-medium">
                        Today
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              {/* filter by location */}
              <div className="px-6 mb-24 border-t pt-5 hidden">
                <p className="text-honeConnectBlack text-base font-semibold mb-3">
                  Filter by Location
                </p>
                <select
                  name=""
                  id=""
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                >
                  <option value="">Select Location</option>
                  <option value="">Option 1</option>
                  <option value="">Option 2</option>
                </select>
              </div>
            </TEModalBody>

            <TEModalFooter className="flex gap-3">
              <TERipple rippleColor="light" className="">
                <button
                  type="button"
                  className="w-full text-honeConnectPurple inline-block rounded bg-primary-100 px-6 py-3 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                  onClick={() => setShowModal(false)}
                >
                  Reset
                </button>
              </TERipple>
              <TERipple rippleColor="light" className="w-2/3">
                <button
                  type="button"
                  className="w-full inline-block rounded px-6 py-3 text-sm font-medium uppercase text-gray-500 bg-honeConnectPurple text-white shadow"
                >
                  Show
                </button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>

      <Preloader loading={laoding} />
    </AppLayout>
  );
};

export default MyRequests;
