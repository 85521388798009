import React from "react";
import Accordion from "./Accordion";

const FAQ: React.FC = () => {
  return (
    <div className="">
      <Accordion
        title="What is HoneConnect?"
        answer="HoneConnect is an innovative social networking platform designed to seamlessly connect like-minded individual with an intuitive user experience, prioritizing user control and privacy."
      />
      <Accordion
        title="How does HoneConnect Work?"
        answer="- Make targeted connection requests with clear intentions. <br><br>
        - Control response limits per request. <br><br>
        - Enjoy privacy on your request via the smart exclusion feature. <br><br>
        - Responders connect to your request to establish meaningful connections."
      />
      <Accordion
        title="What is HoneConnect's value preposition?"
        answer="<strong>Connect with Intent :</strong> Users connect with purpose by specifying their desired response type. <br><br>
        <strong>User Control :</strong> Users have full control over response count and can close a request at anytime.<br><br>
        <strong>User Privacy :</strong>  Users connection requests are not visible to excluded contacts. No profile search functionality safeguarding user privacy. <br><br>
        <strong>Free Unlimited Requests :</strong>  HoneConnect offers limitless and free connection requests for users. <br><br>
        <strong>User Experience :</strong> Users establish meaninful connections within an intuitive and clutter-free interface."
      />
      <Accordion
        title="What is a Connection Request?"
        answer="Users create and share a connection request based on their interests aimed at establishing meaningful connection with other users of shared interests."
      />
      <Accordion
        title="How long does it take to get connected?"
        answer="Connection on the honeconnect app is instant once a response has been made to your open request. "
      />
      <Accordion
        title="How long does an  established connections  last?"
        answer="Established Connections remain in your profile and can be accessed any time."
      />
      <Accordion
        title="How long does a Connection Request remain Open?"
        answer="Connection requests remain open until the specified number of responses is received or the user initiates a closure."
      />
      <Accordion
        title="What is Exclusions?"
        answer="Exclusions are user-specified contacts restricted from viewing the users open connection requests, ensuring user privacy."
      />
      <Accordion
        title="Can I Search for friends?"
        answer="No, HoneConnect prioritizes user privacy by rendering profiles non-searchable."
      />
      <Accordion
        title="Can I have multiple Accounts?"
        answer="No, users are allowed one account per email/ phone number"
      />
      <Accordion
        title="How do I contact HoneConnect support?"
        answer="<a href='mailto:support@honeconnect.com'>support@honeconnect.com</a>"
      />
    </div>
  );
};

export default FAQ;