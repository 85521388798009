import CryptoJS from "crypto-js";

export const APP_STORAGE = process.env.REACT_APP_STORAGE;
export const PAYSTACK_PUBLIC_KEY = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
export const SERVER_URL = process.env.REACT_APP_BASE_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
export const GOOGLE_AUTH_URL = process.env.REACT_APP_GOOGLE_AUTH_URL;
export const GOOGLE_URL = `${GOOGLE_AUTH_URL}?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT_URI}&response_type=token&scope=email profile`;
const secretKey = process.env.REACT_APP_AES_SECRET || "";

// Function to store JWT token in session storage
export const storeToken = (token: string) => {
  localStorage.setItem("aa_public", encryptData(token));
};

// Function to get JWT token from session storage
export const getToken = () => {
  const token = localStorage.getItem("aa_public") || "";
  return decryptData(token);
};

// Function to remove JWT token from session storage
export const removeToken = () => {
  localStorage.removeItem("aa_public");
};

export const destroySession = () => {
  localStorage.clear();
  window.location.reload();
};

// Function to store USER in session storage
export const storeUser = (data: any) => {
  const json_data = JSON.stringify(data);
  localStorage.setItem("aa_data", encryptData(json_data));
};

// Function to get USER from session storage
export const getUser = () => {
  const json_data: any = localStorage.getItem("aa_data");
  return !!json_data ? JSON.parse(decryptData(json_data)) : null;
};

// Function to remove USER from session storage
export const removeUser = () => {
  localStorage.removeItem("aa_data");
};

export const encryptData = (data: any) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

export const decryptData = (cipherText: any) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};



export const encryptDataWithIV = (data: string): string => {
  const iv = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );

  // Concatenate IV with ciphertext and encode as Base64
  const ivAndCiphertext = iv.concat(encrypted.ciphertext);
  const encryptedString = CryptoJS.enc.Base64.stringify(ivAndCiphertext);

  return encryptedString;
};

export const decryptDataWithIV = (cipherText: string): string => {
  // Decode the Base64 string back to a WordArray
  const data = CryptoJS.enc.Base64.parse(cipherText);

  // Extract the IV from the first 16 bytes (4 words)
  const iv = CryptoJS.lib.WordArray.create(data.words.slice(0, 4), 16);

  // Extract the ciphertext (remaining bytes)
  const ciphertext = CryptoJS.lib.WordArray.create(
    data.words.slice(4),
    data.sigBytes - 16
  );

  // Create a valid CipherParams object
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: ciphertext,
  });

  // Decrypt using AES with the extracted IV and ciphertext
  const decrypted = CryptoJS.AES.decrypt(
    cipherParams,
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );

  // Convert the decrypted data back to a UTF-8 string
  const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

  return decryptedString;
};
