import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HoneConnectLogo, HoneConnectLogoII } from "../../../assets/img";
import { SITELINKS } from "../../../app-routes/links";
import { Menu, X } from "lucide-react";

const WebsiteHeader = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-honeConnectPurple md:bg-white sticky top-0 z-20 py-5 px-5 md:py-0 md:px-0">
      {/* Desktop NavBar & Menu 👇👇 */}
      <div className="hidden md:flex justify-between items-center w-11/12 mx-auto py-4">
        {/* logo area */}
        <div>
          <Link to="/">
            <img src={HoneConnectLogo} alt="app logo" className="" />
          </Link>
        </div>

        <div className="flex items-center gap-x-40 text-[16px]">
          {/* <Menu /> <X /> */}
          {/* NAV LINKS */}
          <ul className="hidden lg:flex gap-12 font-medium">
            <li className="active text-honeConnectPurple border-b-2 border-honeConnectPurple border-solid ">
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#benefit">Our Benefit</a>
            </li>
            <li>
              <a href="#howItWorks">How It Works</a>
            </li>
            <li>
              <a href="#faq">FAQ</a>
            </li>
          </ul>
          {/* NAV SIGN IN */}
          <div>
            {/* <Link to="/">fd</Link> */}
            <button
              onClick={() => {
                localStorage.clear();
                navigate(SITELINKS.LOGIN);
              }}
              className="py-3 px-8 font-semibold text-white rounded-[10px] bg-honeConnectPurple"
            >
              Sign In
            </button>
          </div>
        </div>
      </div>

      {/* Mobile NavBar & Menu 👇👇 */}
      <div className="md:hidden">
        <div className="flex justify-between">
          {/* logo Mobile */}
          <div>
            <Link to="/">
              <img
                src={HoneConnectLogoII}
                alt="app logo"
                className="w-[100px] rounded-md"
              />
            </Link>
          </div>

          <button onClick={toggleNavbar}>
            {isOpen ? (
              <X size={40} color="white" />
            ) : (
              <Menu size={40} color="white" />
            )}
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="flex flex-col text-center gap-8 text-sm py-10">
          {/* NAV LINKS */}
          <ul className="flex flex-col gap-8 font-medium text-white">
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#benefit">Our Benefit</a>
            </li>
            <li>
              <a href="#howItWorks">How It Works</a>
            </li>
            <li>
              <a href="#faq">FAQ</a>
            </li>
          </ul>
          {/* NAV SIGN IN */}
          <div className="px-6">
            <Link
              to={SITELINKS.LOGIN}
              className="py-3 w-full shadow font-medium text-honeConnectPurple rounded-[10px] bg-white border border-solid border-white hover:bg-honeConnectPurple hover:border-white hover:text-white block"
            >
              Sign In
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};
export default WebsiteHeader;
