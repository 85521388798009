import React from "react";
import { Ads } from "../assets/img";

const AdsBanner = () => {
  return (
    <div className="col-span-12 md:col-span-5 lg:col-span-2 hidden">
      <img src={Ads} alt="Advertisement Banner" />
    </div>
  );
};

export default AdsBanner;
