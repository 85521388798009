import React from "react";

const MyProfileSideBar = () => {
  return (
    // <!-- Column 3 -->
    <div className="hidden md:col-span-4 lg:col-span-3 md:flex flex-col gap-6 bg-white rounded-10px">
      {/* <!-- ROW --> */}
      <div>
        <img src="img/Rectangle 40073.png" alt="mom" />
      </div>
      {/* <!-- ROW --> */}
      <div className="text-center -mt-14">
        <img
          src="img/User-square.png"
          className="w-16 inline-block"
          alt="User Display"
        />
        <p className="font-bold text-xl mt-2">Taiwo84</p>
        <div className="flex justify-center items-center gap-2 text-sm text-honeConnectGray font-normal">
          <i className="fa-solid fa-location-dot"></i>
          <p>Surulere, Lagos</p>
        </div>
      </div>
      {/* <!-- ROW --> */}
      <div className="mt-6">
        {/* <!-- ROW --> */}
        <div className="items-center py-3 px-6 border-b border-t border-solid border-hc[#b1b1b1]">
          <button
            type="submit"
            className="bg-honeConnectPurple text-white w-full p-3 rounded-lg border-0 text-base"
          >
            Transactions
          </button>
        </div>
      </div>
      {/* <!-- ROW --> */}
      {/* <!-- Flex item not working yet. Fix later --> */}
      <div className="px-6 mt-96 mb-8">
        <div className="bg-honeConnectPurple rounded-10px p-6 flex flex-col gap-4 items-center">
          <div className="-mt-20 w-28 h-28 border-4 border-hc_F3A838 border-solid bg-white rounded-full"></div>
          <p className="text-center text-white text-sm font-semibold">
            Upgrade your plan, for more features and a better user experience.
          </p>
          <button
            type="submit"
            className="bg-white text-honeConnectPurple py-2 px-6 rounded-lg border-0 text-sm font-semibold"
          >
            Upgrade Now
          </button>
        </div>
      </div>
      {/* <!-- className="md:col-span-4 lg:col-span-3 flex flex-col gap-6 bg-white rounded-10px" --> */}
    </div>
  );
};

export default MyProfileSideBar;
