import {useQuery } from '@tanstack/react-query';
import apiClient  from '../../ApiClient';

const getNotification = async () => {
  const res = await apiClient.get("notifications/unread-count");
  return res.data;
};

export function useUnreadNotificationCounter() {
  return useQuery({
    queryKey: ['unread_notification_counter'],
    queryFn: getNotification,
  });
}