import React from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import SuggestionsSideBar from "../../app-components/SuggestionsSideBar";

const Settings = () => {
  return (
    <AppLayout>
      <main className="sm:py-10">
        <div className="grid grid-cols-12 gap-8 justify-between w-full sm:w-11/12 mx-auto">
          {/* <!-- COlumn 3 --> */}
          <SuggestionsSideBar />
          {/* <!-- COlumn 7 --> */}
          <div className="col-span-12 md:col-span-8 lg:col-span-7 sm:rounded-10px">
            {/* <!-- ROW --> */}
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Account Information
              </p>
              <div>
                <a
                  href="#edit"
                  className="text-honeConnectPurple font-medium text-sm underline"
                >
                  Edit
                </a>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="rounded-10px bg-honeConnectGray50 border border-solid border-honeConnectGray200 p-5 mb-5">
              <form action="">
                {/* <!-- Row In Form --> */}
                <div className="grid lg:grid-cols-1 xl:grid-cols-2 gap-2">
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="FullName" className="mb-1.5">
                      Fullname
                    </label>
                    <input
                      type="text"
                      className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                      placeholder="Adetayo Taiwo"
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="FullName" className="mb-1.5">
                      Email
                    </label>
                    <input
                      type="text"
                      className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                      placeholder="@adebayotaiwo8734@gmail.com"
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="userID" className="mb-1.5">
                      User ID / Profile Name
                    </label>
                    <input
                      type="text"
                      className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                      placeholder="Taiwo84"
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="dob" className="mb-1.5">
                      Date of Birth
                    </label>
                    <select
                      name=""
                      id=""
                      className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="gender" className="mb-1.5">
                      Gender
                    </label>
                    <select
                      name=""
                      id=""
                      className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                    >
                      <option value="">Select</option>
                      <option value="">Male</option>
                      <option value="">Female</option>
                    </select>
                  </div>
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="xID" className="mb-1.5">
                      X ID
                      <span className="text-honeConnectOrange">(optional)</span>
                    </label>
                    <input
                      type="text"
                      className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                      placeholder="@adebayot"
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="location" className="mb-1.5">
                      Location
                    </label>
                    <select
                      name=""
                      id=""
                      className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="snapchatID" className="mb-1.5">
                      Snapchat ID
                      <span className="text-honeConnectOrange">(optional)</span>
                    </label>
                    <input
                      type="text"
                      className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                      placeholder="@adebayot"
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="number" className="mb-1.5">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                      placeholder="80 000 0000"
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                    <label htmlFor="instagramID" className="mb-1.5">
                      Instagram ID
                      <span className="text-honeConnectOrange">(optional)</span>
                    </label>
                    <input
                      type="text"
                      className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                      placeholder="@adebayot"
                    />
                  </div>
                </div>
              </form>
            </div>
            {/* <!-- ROW --> */}
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Account Privacy
              </p>
            </div>
            {/* <!-- ROW --> */}
            <div className="rounded-10px bg-honeConnectGray50 border border-solid border-honeConnectGray200 p-5 mb-5">
              <p className="text-sm text-honeConnectBlack font-normal mb-3">
                Private users will have their profiles and requests open to only
                selected users.
              </p>
              <div className="flex gap-8">
                <div className="flex gap-4">
                  <input type="checkbox" name="on" />
                  <label
                    htmlFor="on"
                    className="text-sm text-honeConnectGray font-medium"
                  >
                    ON
                  </label>
                </div>
                <div className="flex gap-2">
                  <input type="checkbox" name="on" />
                  <label
                    htmlFor="on"
                    className="text-sm text-honeConnectGray font-medium"
                  >
                    OFF
                  </label>
                </div>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Notification Summary
              </p>
            </div>
            {/* <!-- ROW --> */}
            <div className="rounded-10px bg-honeConnectGray50 border border-solid border-honeConnectGray200 p-5 mb-5">
              <p className="text-sm text-honeConnectBlack font-normal mb-3">
                Receive an email summary of notifications.
              </p>
              <div className="flex gap-8">
                <div className="flex gap-4">
                  <input type="checkbox" name="on" />
                  <label
                    htmlFor="on"
                    className="text-sm text-honeConnectGray font-medium"
                  >
                    ON
                  </label>
                </div>
                <div className="flex gap-2">
                  <input type="checkbox" name="on" />
                  <label
                    htmlFor="on"
                    className="text-sm text-honeConnectGray font-medium"
                  >
                    OFF
                  </label>
                </div>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Exclusions
              </p>
            </div>
            {/* <!-- ROW --> */}
            <div>
              <p className="text-sm text-honeConnectGray font-medium mb-1">
                Your profiles will be excluded searches or open requests.
              </p>
              <p className="text-sm text-honeConnectGray font-medium">
                You will be able to import contacts (emails/phone numbers) your
                mobile device to the exclusion list.
              </p>
              <p className="text-sm text-honeConnectBlack font-medium mt-6 mb-1">
                Exclusion lists for users:
              </p>
              <p className="text-sm text-honeConnectGray font-medium mb-1">
                <span className="text-honeConnectOrange">Standard users:</span>{" "}
                5 phone numbers and 5 email addresses.
              </p>
              <p className="text-sm text-honeConnectGray font-medium mb-1">
                <span className="text-honeConnectOrange">Gold users:</span> 50
                phone numbers and 50 email addresses
              </p>
              <p className="text-sm text-honeConnectGray font-medium mb-1">
                <span className="text-honeConnectOrange">Platinum users:</span>{" "}
                300 phone numbers and 300 email addresses.
              </p>
            </div>
            {/* <!-- ROW --> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-5">
              <div className="flex flex-col gap-1">
                <p className="text-sm text-honeConnectGray font-medium">
                  Add Email
                </p>
                <div className="flex">
                  <input
                    className="w-[80%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-l-10px focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                    placeholder="Type a text here"
                  />
                  <button
                    type="submit"
                    className="bg-honeConnectPurple text-white w-[20%] p-3 rounded-r-lg border-0 text-base"
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm text-honeConnectGray font-medium">
                  Add Phone Number
                </p>
                <div className="flex">
                  <input
                    className="w-[80%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-l-10px focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                    placeholder="Type a text here"
                  />
                  <button
                    type="submit"
                    className="bg-honeConnectPurple text-white w-[20%] p-3 rounded-r-lg border-0 text-base"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-5">
              <div className="rounded-10px bg-honeConnectGray50 border border-solid border-honeConnectGray200 pt-5 pb-32 px-5 flex flex-col gap-2">
                <div className="flex justify-between gap-5 items-center mb-2">
                  <p className="text-sm text-honeConnectGray font-normal">
                    ex: myname@example.com
                  </p>
                  <a href="#delete">
                    <i className="fa-solid fa-trash text-honeConnectOrange text-"></i>
                  </a>
                </div>
                <div className="flex justify-between gap-5 items-center">
                  <p className="text-sm text-honeConnectGray font-normal">
                    ex: myname@example.com
                  </p>
                  <a href="#delete">
                    <i className="fa-solid fa-trash text-honeConnectOrange text-"></i>
                  </a>
                </div>
              </div>
              <div className="rounded-10px bg-honeConnectGray50 border border-solid border-honeConnectGray200 pt-5 pb-32 px-5 flex flex-col gap-2">
                <div className="flex justify-between gap-5 items-center mb-2">
                  <p className="text-sm text-honeConnectGray font-normal">
                    080 000 0000
                  </p>
                  <a href="#delete">
                    <i className="fa-solid fa-trash text-honeConnectOrange text-"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Column 2 --> */}
          <AdsBanner></AdsBanner>
        </div>
      </main>
    </AppLayout>
  );
};

export default Settings;
