import React, { useState, FC } from "react";
import { xToggle, plusToggle } from "../../../assets/img";
import DOMPurify from "dompurify";

interface AccordionProps {
  title: string;
  answer: string;
}

const purifyHTML = (html: any) => {
  return DOMPurify.sanitize(html);
};

const Accordion: FC<AccordionProps> = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  return (
    <div className="py-2 flex-row gap-10">
      <div className="border-solid border-b-[1px] border-[#D9D9D9] pb-7">
        <button
          onClick={() => setAccordionOpen(!accordionOpen)}
          className="flex w-full gap-4 items-start  "
        >
          {accordionOpen ? (
            <span>
              <img src={xToggle} alt="" className="max-w-8 md:w-16" />
            </span>
          ) : (
            <span>
              <img src={plusToggle} alt="" className="max-w-8 md:w-16" />
            </span>
          )}
          <span className="text-left text-lg md:text-[20px] font-bold mb-2 text-[#061737]">
            {title}
          </span>
        </button>
        <div
          className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
            accordionOpen
              ? "grid-rows-[1fr] opacity-100"
              : "grid-rows-[0fr] opacity-0"
          }`}
        >
          <div className="overflow-hidden text-[#6D6D6D] text-sm font-medium md:font-semibold ml-12">
            <div dangerouslySetInnerHTML={{ __html: purifyHTML(answer) }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;