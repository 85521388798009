import React from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import SuggestionsSideBar from "../../app-components/SuggestionsSideBar";

const RequestRequester = () => {
  return (
    <AppLayout>
      <main className="sm:py-10">
        <div className="grid grid-cols-12 gap-8 justify-between w-full sm:w-11/12 mx-auto">
          {/* <!-- COlumn 3 --> */}
          <SuggestionsSideBar />
          {/* <!-- COlumn 7 --> */}
          <div className="col-span-12 md:col-span-8 lg:col-span-7 sm:rounded-10px">
            {/* <!-- ROW --> */}
            <div className="sm:bg-white sm:p-3 px-5 rounded-10px -mb-[50px] sm:mb-5 flex justify-between items-center">
              <div className="">
                <a
                  href="#!"
                  className="text-white sm:text-honeConnectPurple font-semibold text-sm"
                >
                  <i className="fa-solid fa-chevron-left"></i> Back
                </a>
              </div>
              <button className="text-sm py-2 px-4 border border-solid bg-hc_F3A838 font-semibold text-white rounded-[5px]">
                + Favorite
              </button>
            </div>
            {/* <!-- ROW 1 --> */}
            <div
              style={{ backgroundImage: `url('img/Group1000004983.png')` }}
              className="bg-cover bg-center p-8 sm:rounded-t-[10px] -mb-[100px] sm:-mb-0"
            >
              <div className="flex gap-4 sm:gap-6 items-center mt-[45px] sm:mt-0">
                <div className="relative">
                  <img src="img/Esther.png" alt="" className="w-40" />
                  <img
                    src="img/gold-medal-2.png"
                    className="w-16 absolute -top-6 -right-6"
                    alt=""
                  />
                </div>
                <div className="text-white flex flex-col gap-2 -mt-[60px] sm:mt-10">
                  <p className="font-semibold text-xl">Barbara Esther, 25</p>
                  <div className="flex items-center gap-2 text-sm font-normal">
                    <i className="fa-solid fa-location-dot"></i>
                    <p>Surulere, Lagos</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-white p-8 pt-[100px] sm:pt-8">
              <div className="flex flex-col gap-4">
                <p className="font-semibold text-base text-honeConnectBlack">
                  My Bio
                </p>
                <div className="flex justify-between gap-28">
                  <p className="text-honeConnectBlack font-normal text-sm">
                    Duis faucibus egestas turpis in dignissim. Mauris ac pretium
                    nunc. Vestibulum ullamcorper sagittis nibh at finibus. Sed
                    suscipit sempe
                  </p>
                  <a
                    href="#edit"
                    className="text-honeConnectPurple font-medium text-sm underline self-end"
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-white p-8 mt-5">
              <div className="flex flex-col gap-4">
                <p className="font-semibold text-base text-honeConnectBlack">
                  My Interest
                </p>
                {/* <!-- ROW --> */}
                <div className="flex justify-between lg:gap-28">
                  <div className="flex flex-wrap gap-4 mt-4">
                    <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px]">
                      Mingle
                    </p>
                    <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px]">
                      Friends
                    </p>
                    <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px]">
                      Dating
                    </p>
                    <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px]">
                      Relationship
                    </p>
                    <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px]">
                      Convo
                    </p>
                  </div>
                  <a
                    href="#edit"
                    className="text-honeConnectPurple font-medium text-sm underline self-end"
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-white p-8 mt-5">
              <div className="flex flex-col gap-6">
                <p className="font-semibold text-base text-honeConnectBlack">
                  Social Account
                </p>
                <div className="flex flex-wrap justify-between gap-28">
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-10 items-center">
                    <div className="flex gap-2 items-center">
                      <img src="img/IG.png" alt="" className="w-10" />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs text-honeConnectGray font-medium">
                          Instagram
                        </p>
                        <p className="text-sm font-normal text-honeConnectBlack">
                          @adebayot
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src="img/Snapchat.png" alt="" className="w-10" />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs text-honeConnectGray font-medium">
                          Snapchat
                        </p>
                        <p className="text-sm font-normal text-honeConnectBlack">
                          @adebayot
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src="img/WhatsApp.png" alt="" className="w-10" />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs text-honeConnectGray font-medium">
                          WhatsApp
                        </p>
                        <p className="text-sm font-normal text-honeConnectBlack">
                          0800000000
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="hidden xl:block"></div>
                </div>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-white p-8 mt-5">
              <div className="flex flex-col gap-6">
                <p className="font-semibold text-base text-honeConnectBlack">
                  Photos
                </p>
                <div className="flex justify-between gap-28">
                  <div className="flex gap-10 flex-wrap items-center">
                    <div className="relative">
                      <img src="img/Esther.png" alt="" className="w-28" />
                      {/* <!-- Number in circle --> */}
                      <div className="absolute -top-3 -right-3 w-[30px] h-[30px] border-[3px] border-honeConnectPurple border-solid rounded-[50%] flex justify-center items-center bg-white">
                        <span className="text-sm font-semibold text-honeConnectPurple">
                          1
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="relative">
                        <a href="#upload">
                          <img src="img/Esther-2.png" alt="" className="w-28" />
                        </a>

                        {/* <!-- Number in circle --> */}
                        <div className="absolute -top-3 -right-3 w-[30px] h-[30px] border-[3px] border-honeConnectPurple border-solid rounded-[50%] flex justify-center items-center bg-white">
                          <span className="text-sm font-semibold text-honeConnectPurple">
                            2
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    href="#edit"
                    className="text-honeConnectPurple font-medium text-sm underline self-end"
                  >
                    See all Photos
                  </a>
                </div>
                {/* <!-- <a
        href=""
        className="text-honeConnectOrange text-xs font-medium"
        >Upload Photo</a
      > --> */}
              </div>
            </div>

            {/* <!-- ROW --> */}
            <div className="bg-white p-8 mt-5">
              <p className="font-semibold text-base text-honeConnectBlack mb-5">
                Phone Call & WhatsApp
              </p>
              <div className="flex justify-center gap-20">
                <a href="#phonecall">
                  <div className="flex flex-col gap-3 items-center">
                    <img src="img/PhoneCall.png" alt="" className="w-[70px]" />
                    <p className="text-xs font-medium text-honeConnectGray text-center">
                      Call now
                    </p>
                  </div>
                </a>
                <a href="#whatsapp">
                  <div className="flex flex-col gap-3 items-center">
                    <img src="img/WhatsApp.png" alt="" className="w-[70px]" />
                    <p className="text-xs font-medium text-honeConnectGray text-center">
                      Chat now <br />
                      on WhatsApp
                    </p>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-white p-8 mt-5 rounded-b-[10px]">
              <p className="font-semibold text-base text-honeConnectBlack mb-5">
                Phone Call & WhatsApp
              </p>

              <div className="flex">
                <input
                  className="w-[80%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-l-10px focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="Type a text here"
                />
                <button
                  type="submit"
                  className="bg-honeConnectPurple text-white w-[20%] p-3 rounded-r-lg border-0 text-base"
                >
                  Send
                </button>
              </div>
              <p className="text-sm font-medium text-honeConnectGray mt-2">
                <span className="text-honeConnectOrange">0</span>/76 Characters
              </p>
            </div>
          </div>
          {/* <!-- Column 2 --> */}
          <AdsBanner></AdsBanner>
        </div>
      </main>
    </AppLayout>
  );
};

export default RequestRequester;
