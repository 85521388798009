// login page;
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../app-layouts/AuthLayout";
import { inputStyle } from "../../app-util/helpers";
import { FormEvent, useState } from "react";
import apiClient from "../../api-services/ApiClient";
import { SITELINKS } from "../../app-routes/links";
import PasswordToggle from "../../app-components/PasswordToggle";

const UpdatePassword = () => {
  const navigate = useNavigate();

  // component-state
  const [pageLoading, setPageLoading] = useState(false);
  const [formData, setFormData] = useState<any>({
    password2: "",
    password: "",
  });

  // server-error-display
  const [serverError, setServerError] = useState<string>("");

  // handle-input-change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdatePassword = async (e: FormEvent) => {
    e.preventDefault();
    setServerError("");

    if (formData.password !== formData.password2) {
      setServerError("Password and confirm password did not match");
      return;
    }

    if (formData.password) {
      try {
        setPageLoading(true);
        const data = {
          password: formData.password,
        };
        await apiClient.patch("update-password", data);

        navigate(SITELINKS.HOME);
      } catch (error: any) {
        setServerError(JSON.stringify(error));
      } finally {
        setPageLoading(false);
      }
    } else {
      setServerError("Password cannot be empty");
    }
  };

  return (
    <AuthLayout>
      <section className="py-3 px-0 lg:col-span-3">
        {/* form section */}
        <div className="pt-10 lg:pt-20 pb-0 px-2 lg:px-10 lg:px-28 text-honeConnectGray">
          {/* hero-text area */}
          <div>
            <h4 className="text-xl text-honeConnectBlack font-bold mb-2.5">
              Update Password
            </h4>
            <p className="text-xs mb-8 font-medium">
              To keep connected kindly update your <br />
              login password in the below fileds.
            </p>
          </div>

          {/* react-login-form */}
          <form
            onSubmit={handleUpdatePassword}
            autoComplete="off"
            autoCorrect="off"
          >
            <div className="flex flex-col text-sm text-gray-400 font-medium mb-4 relative">
              <label htmlFor="Password" className="mb-1">
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password_input"
                onChange={handleInputChange}
                className={`${inputStyle}`}
                placeholder="Enter your password"
                autoComplete="off"
                value={formData.password}
              />

              <div className="absolute top-[2.1rem] right-3">
                <PasswordToggle targetId="password_input" />
              </div>
            </div>

            <div className="flex flex-col text-sm text-gray-400 font-medium mb-6 relative">
              <label htmlFor="Password" className="mb-1">
                Re enter password
              </label>
              <input
                type="password"
                name="password2"
                id="password_input_2"
                onChange={handleInputChange}
                className={`${inputStyle}`}
                placeholder="Enter your password"
                autoComplete="off"
                value={formData.password2}
              />

              <div className="absolute top-[2.1rem] right-3">
                <PasswordToggle targetId="password_input_2" />
              </div>
            </div>

            <p className="text-red-600 text-[12px] text-center mb-3">
              {serverError}
            </p>

            <button
              type="submit"
              className="disabled:bg-gray-600 bg-honeConnectPurple text-white text-sm w-full p-3 rounded-lg border-0"
              disabled={pageLoading}
            >
              Update Password
            </button>
          </form>
        </div>
      </section>
    </AuthLayout>
  );
};

export default UpdatePassword;
