import { useState } from "react";
import { LuMapPin } from "react-icons/lu";
import { APP_STORAGE, getUser } from "../api-services/process";
import { UserProfileInterface } from "../app-schemas/user.interface";
import { UserImg } from "../assets/img";
import { useInterest } from "../api-services/api-query/Interest/useInterests";

const HomePageSideNav = () => {
  const user: UserProfileInterface = getUser();
  const interestData = useInterest();
  const [interestId, setInterestId] = useState<number>(1);

  const filterInterest = (data: any) => {
    if (data) {
      const uniqueInterests = new Map();

      data.forEach((item: any) => {
        const interest = item.interest;
        uniqueInterests.set(interest.id, interest);
      });
      const interestsArray = Array.from(uniqueInterests.values());

      return interestsArray;
    }

    return [];
  };
  return (
    <div className="hidden sm:col-span-4 md:col-span-4 lg:col-span-3 sm:flex flex-col bg-white rounded-10px">
      <div>
        <img src="img/Rectangle 40073.png" alt="moom" />
      </div>

      {/* profile area */}
      <div className="text-center -mt-14 py-6">
        {user.profile_pix_link ? (
          <img
            src={APP_STORAGE + user.profile_pix_link}
            alt="User"
            className="w-16 inline-block rounded-xl border-[1px] border-honeConnectPurple"
          />
        ) : (
          <img
            src={UserImg}
            alt="User"
            className="w-16 inline-block rounded-xl border-[1px] border-honeConnectPurple"
          />
        )}

        <p className="font-semibold text-xl mt-2 capitalize">{user.username}</p>

        <div className="flex justify-center items-center gap-2 text-honeConnectGray font-normal">
          <div>
            <LuMapPin className="drop-box-shadow text-sm" />
          </div>
          <p className="text-sm">
            {user?.city?.name}, {user?.state?.name}
          </p>
        </div>
      </div>

      {/* filter area */}
      <h3 className="bg-[#e2e2e2] py-1 px-3 text-honeConnectBlack text-lg font-semibold">
        Filters:
      </h3>

      <div className="px-3 py-3">
        <p className="text-honeConnectBlack text-xs font-medium mb-3">
          Filter by Interest
        </p>

        <div className="grid xl:grid-cols-2 gap-y-5 items-center mt-2 mb-3">
          {filterInterest(interestData.data).map((item: any) => {
            return (
              <label
                key={item.id}
                className="items-center xl:col-span-1 custom-checkbox"
              >
                <input
                  type="checkbox"
                  name=""
                  id="col1"
                  className="h-5 w-5 accent-purple"
                  onChange={(e) => {
                    setInterestId(item.id);
                  }}
                  checked={item.id === interestId}
                />
                <span className="checkmark"></span>
                <span className="text-sm text-honeConnectGray font-medium">
                  {item.interest}
                </span>
              </label>
            );
          })}
        </div>
      </div>

      {/* filter by gender */}
      <div className="px-3 border-t py-3">
        <p className="text-honeConnectBlack text-xs font-medium mb-3">
          Filter by Gender
        </p>

        <div className="flex gap-7">
          <label className="items-center xl:col-span-1 custom-checkbox">
            <input
              type="checkbox"
              name=""
              id="col1"
              className="h-5 w-5 accent-purple"
            />
            <span className="checkmark"></span>
            <span className="text-sm text-honeConnectGray font-medium">
              Male
            </span>
          </label>

          <label className="items-center xl:col-span-1 custom-checkbox">
            <input
              type="checkbox"
              name=""
              id="col1"
              className="h-5 w-5 accent-purple"
            />
            <span className="checkmark"></span>
            <span className="text-sm text-honeConnectGray font-medium">
              Female
            </span>
          </label>
        </div>
      </div>

      {/* filter by date */}
      <div className="px-3 py-3 border-t">
        <p className="text-honeConnectBlack text-xs font-semibold mb-3">
          Filter by Date
        </p>

        <div className="flex flex-col gap-5 mt-2">
          <div className="flex gap-2">
            <label className="items-center xl:col-span-1 custom-checkbox">
              <input
                type="checkbox"
                name=""
                id="col1"
                className="h-5 w-5 accent-purple"
              />
              <span className="checkmark"></span>
              <span className="text-sm text-honeConnectGray font-medium">
                All time
              </span>
            </label>
          </div>

          <div className="flex gap-2">
            <label className="items-center xl:col-span-1 custom-checkbox">
              <input
                type="checkbox"
                name=""
                id="col1"
                className="h-5 w-5 accent-purple"
              />
              <span className="checkmark"></span>
              <span className="text-sm text-honeConnectGray font-medium">
                Last 7 days
              </span>
            </label>
          </div>

          <div className="flex gap-2">
            <label className="items-center xl:col-span-1 custom-checkbox">
              <input
                type="checkbox"
                name=""
                id="col1"
                className="h-5 w-5 accent-purple"
              />
              <span className="checkmark"></span>
              <span className="text-sm text-honeConnectGray font-medium">
                Last 3 days
              </span>
            </label>
          </div>

          <div className="flex gap-2">
            <label className="items-center xl:col-span-1 custom-checkbox">
              <input
                type="checkbox"
                name=""
                id="col1"
                className="h-5 w-5 accent-purple"
              />
              <span className="checkmark"></span>
              <span className="text-sm text-honeConnectGray font-medium">
                Last 24 hours
              </span>
            </label>
          </div>

          <div className="flex gap-2">
            <label className="items-center xl:col-span-1 custom-checkbox">
              <input
                type="checkbox"
                name=""
                id="col1"
                className="h-5 w-5 accent-purple"
              />
              <span className="checkmark"></span>
              <span className="text-sm text-honeConnectGray font-medium">
                Today
              </span>
            </label>
          </div>
        </div>
      </div>

      {/* filter by location */}
      <div className="px-6 mb-24 border-t pt-5 hidden">
        <p className="text-honeConnectBlack text-base font-semibold mb-3">
          Filter by Location
        </p>
        <select
          name=""
          id=""
          className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
        >
          <option value="">Select Location</option>
          <option value="">Option 1</option>
          <option value="">Option 2</option>
        </select>
      </div>
    </div>
  );
};

export default HomePageSideNav;
