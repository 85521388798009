import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { APP_STORAGE, getUser } from "../../api-services/process";
import { SITELINKS } from "../../app-routes/links";
import { UserProfileInterface } from "../../app-schemas/user.interface";
import { BellIcon, SettingsIcon } from "../../assets/custom-icons";
import { HoneConnectLogo, UserImg } from "../../assets/img";

const Header = () => {
  const user: UserProfileInterface = getUser();
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    //! API.get->notificationCount;
    setNotificationCount(3);
  }, []);

  return (
    <header className="hidden md:block bg-white">
      <div className="flex justify-between items-center w-11/12 mx-auto py-1 container mx-auto">

        {/* logo area */}
        <div>
          <Link to={SITELINKS.HOME}>
            <img src={HoneConnectLogo} alt="app logo" className="h-[50px]" />
          </Link>
        </div>

        {/* notification ->  */}
        <div className="flex items-center gap-6">
          {/* notification */}
          <div className="text-honeConnectGray200 mt-2">
            <Link to={SITELINKS.NOTIFICATIONS} className="relative">
              <img src={BellIcon} alt="bell" className="h-[25px]" />
              {notificationCount > 0 && (
                <div className="absolute -top-3 -right-3 w-[25px] h-[25px] rounded-[50%] flex justify-center items-center bg-[#e99b27] border-[3px] border-white border-solid">
                  <span className="text-xs font-semibold text-white relative top-[1px]">
                    {notificationCount}
                  </span>
                </div>
              )}
            </Link>
          </div>

          {/* settings link */}
          <div className="text-honeConnectGray200">
            <Link to={SITELINKS.SETTINGS}>
              <img src={SettingsIcon} alt="cogs" className="h-[30px]" />
            </Link>
          </div>

          {/* loggedin user name area */}
          <div className="text-sm font-medium">Hello, {user.full_name}</div>

          {/* user i age */}
          <div>
            <div className="relative group">
              {user.profile_pix_link ? (
                <img
                  src={APP_STORAGE + user.profile_pix_link}
                  alt="User"
                  className="w-10 flex rounded-[50px] border-2 border-honeConnectPurple"
                />
              ) : (
                <img
                  src={UserImg}
                  alt="User"
                  className="w-10 flex rounded-[50px] border-2 border-honeConnectPurple"
                />
              )}

              <ul
                style={{ left: "-75px", top: "43px", fontSize: "14px" }}
                className="absolute hidden mt-1 space-y-2 bg-white border border-gray-300 py-2 rounded-md shadow-lg w-[130px] left-0 z-10 group-hover:block dark:bg-gray-600 dark:text-gray-400"
              >
                <li className="p-2">
                  <Link to={SITELINKS.MY_PROFILE}>
                    <span className="ml-3"> My Profile </span>
                  </Link>
                </li>

                <li className="p-2 bg-honeConnectPurple text-white">
                  <Link to={SITELINKS.LOGIN}>
                    <span className="ml-3"> Logout </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
