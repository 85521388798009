import { LuSquare } from "react-icons/lu";
import { RiCheckboxFill } from "react-icons/ri";

interface CheckboxProps {
  checked?: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
}) => {
  return (
    <div className="flex gap-2">
      <div
        className={`text-2xl flex items-center justify-center rounded cursor-pointer ${
          checked
            ? "text-honeConnectPurple"
            : "text-gray-500"
        }`}
        onClick={() => onChange(!checked)}
      >
        {checked ? (
          <RiCheckboxFill />
        ) : (
          <LuSquare style={{ strokeWidth: "1px" }} />
        )}
      </div>
      {label && (
        <label className="mt-[5px]" onClick={() => onChange(!checked)}>
          {label}
        </label>
      )}
    </div>
  );
};
