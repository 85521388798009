import React from "react";

const Preloader = ({ loading }: { loading: boolean }) => {
  return loading ? (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-3 px-5 rounded-lg flex flex-col items-center">
        <div className="rounded-full border-2 border-gray-300 border-t-2 border-t-purple-500 h-12 w-12 mb-4 animate-spin"></div>
        <p className="text-gray-500 text-[10px] font-medium">Loading...</p>
      </div>
    </div>
  ) : null;
};

export default Preloader;
