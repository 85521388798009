import { Link } from "react-router-dom";
import WebsiteHeader from "./website-components/WebsiteHeader";
import WebsiteFooter from "./website-components/WebsiteFooter";
import {
  GooglePlayStore,
  AppleStore,
  HeroImg,
  TargetedConnection,
  SmartExclusions,
  SmartExclusionsPurple,
  FreeConnectionRequests,
  Iphone14Mockup,
  FaqImg,
  testimonialImg1,
  testimonialImg2,
  testimonialImg3,
  testimonialImg4,
  testimonialImg5,
} from "../../assets/img";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import FAQ from "./website-components/FAQ";

const WebsiteIndex = () => {
  return (
    <main>
      <WebsiteHeader />
      {/* HERO SECTION */}
      <div className="bg-[#F8FAFC]">
        <div className="w-11/12 mx-auto py-10 md:py-16 md:pb-16 grid md:grid-rows-2 lg:grid-rows-none lg:grid-cols-2 gap-10">
          <div className="pr-0 lg:pr-20 text-center lg:text-left">
            <h1 className="text-[30px] md:text-[55px] font-bold mb-5 md:mb-8 lg:mb-4 leading-tight text-left">
              <span className="text-honeConnectPurple">Easy</span> and
              <span className="text-honeConnectPurple"> Fast</span> Way To
              Connect
            </h1>
            <p className="text-[#6D6D6D] text-sm text-[16px] md:pr-0 lg:pr-40 mb-5 md:mb-8 lg:mb-4 font-medium text-left">
              Choose the preferences of the person you want to connect with, and
              you'll be well on your way to establishing a connection in no
              time.
            </p>
            <div className="mx-auto block md:hidden">
              <img src={HeroImg} alt="" className="md:h-[500px]" />
            </div>
            <button className="py-3 px-16 font-semibold text-white rounded-[10px] bg-honeConnectPurple mb-5 md:mb-8 lg:mb-4">
              Free Sign Up
            </button>
            <p className="font-medium text-[#6D6D6D] text-[16px] mb-5 md:mb-8 lg:mb-4">
              Download App From
            </p>
            <div className="flex flex-col md:flex-row items-center md:items-start gap-8 justify-center lg:justify-normal">
              <a href="https://google.com">
                <img src={GooglePlayStore} alt="" className="h-14" />
              </a>

              <a href="https://applestore.com">
                <img src={AppleStore} alt="" className="h-14" />
              </a>
            </div>
          </div>

          <div className="hidden md:block mx-auto">
            <img src={HeroImg} alt="" className="md:h-[500px]" />
          </div>
        </div>
      </div>
      {/* Hone Connect Benefit Section*/}
      <div className="w-11/12 mx-auto py-10 lg:py-16 grid">
        {/* ROW */}
        <h1 className="text-[25px] md:text-[40px] font-bold mb-10 md:mb-16 text-center">
          Hone Connect<span className="text-honeConnectPurple"> Benefit</span>
        </h1>
        {/* ROW */}
        <div className="grid md:grid-cols-3 justify-between text-center lg:w-9/12 mx-auto gap-10 lg:gap-20">
          <div className="flex flex-row md:flex-col gap-5 md:gap-2">
            <div className="border-[5px] border-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src={TargetedConnection}
                alt=""
                className="max-w-[40px] md:w-11"
              />
            </div>
            <div className="flex flex-col  text-left md:text-center">
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                Targeted Connection
              </p>
              <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                Establish connections with like-minded individuals based on
                shared interests.
              </p>
            </div>
          </div>
          <div className="flex flex-row md:flex-col gap-5 md:gap-2">
            <div className="hidden md:block border-[5px] border-honeConnectPurple bg-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src={SmartExclusions}
                alt=""
                className="max-w-[40px] md:w-11"
              />
            </div>
            <div className="block md:hidden border-[5px] border-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src={SmartExclusionsPurple}
                alt=""
                className="max-w-[40px] md:w-11"
              />
            </div>
            <div className="flex flex-col  text-left md:text-center">
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                Smart Exclusions
              </p>
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                (Advanced User Privacy)
              </p>
              <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                Requests are hidden from contacts you've excluded.
              </p>
            </div>
          </div>
          <div className="flex flex-row md:flex-col gap-5 md:gap-2">
            <div className="border-[5px] border-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src={FreeConnectionRequests}
                alt=""
                className="max-w-[40px] md:w-11"
              />
            </div>
            <div className="flex flex-col  text-left md:text-center">
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                Free Connection Requests
              </p>
              <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                Enjoy unlimited free connection requests.
              </p>
            </div>
          </div>
        </div>
        {/* ROW */}
        <Link
          to=""
          className="text-right font-medium text-[12px] md:text-sm text-honeConnectPurple underline underline-offset-8 decoration-2 md:mt-0"
        >
          View More
        </Link>
      </div>
      {/* How It Works Section*/}
      <div className="bg-[#F8FAFC]">
        <div className="w-11/12 lg:w-9/12 mx-auto py-10 grid">
          {/* ROW */}
          <div className="text-center lg:px-50 xl:px-80">
            <h1 className="text-[25px] md:text-[40px] font-bold mb-4 leading-tight">
              How It Works:
              <span className="text-honeConnectPurple">
                {" "}
                A-Step By-Step Guide
              </span>
            </h1>
            <p className="text-[#6D6D6D] font-semibold text-sm mb-8">
              Follow these steps mentioned below to proceed with Hone Connect.
            </p>
          </div>
          {/* ROW */}
          <div className="grid grid-cols-12 items-center md:gap-10 lg:gap-20">
            <div className="hidden md:flex md:col-span-12 lg:col-span-5 justify-self-center lg:justify-self-end mb-10 md:mb-0">
              <img src={Iphone14Mockup} alt="" className="h-[450px]" />
            </div>
            <div className="col-span-12 lg:col-span-7 flex flex-col gap-5 md:gap-10">
              {/* Completed */}
              <div className="step completed p-[10px] flex flex-row justify-start gap-5">
                <div className="v-stepper relative">
                  {/* <div className="circle"></div>*/}
                  <div className="circle w-[50px] h-[50px] md:w-[80px] md:h-[80px] rounded-[50%] flex justify-center items-center bg-honeConnectPurple">
                    <span className="text-[30px] md:text-[32px] font-bold text-white">
                      1
                    </span>
                  </div>
                  <div className="line top-[50px] left-[25px] md:top-[80px] md:left-[40px] h-[100%] border-l-[2px] border-dashed border-[#A6A6A6] absolute"></div>
                </div>
                <div className="content">
                  <h1 className="text-lg md:text-[30px] font-bold mb-2">
                    Sign Up
                  </h1>
                  <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                    Provide some basic information about yourself in exchange
                    for access to Hone Connect.
                  </p>
                </div>
              </div>
              <div className="step completed p-[10px] flex flex-row justify-start gap-5">
                <div className="v-stepper relative">
                  {/* <div className="circle"></div>*/}
                  <div className="circle w-[50px] h-[50px] md:w-[80px] md:h-[80px] rounded-[50%] flex justify-center items-center bg-honeConnectPurple">
                    <span className="text-[30px] md:text-[32px] font-bold text-white">
                      2
                    </span>
                  </div>
                  <div className="line top-[50px] left-[25px] md:top-[80px] md:left-[40px] h-[100%] border-l-[2px] border-dashed border-[#A6A6A6] absolute"></div>
                </div>
                <div className="content">
                  <h1 className="text-lg md:text-[30px] font-bold mb-2">
                    Create Profile & Add Preference{" "}
                  </h1>
                  <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                    Create your profile and add your preferences. Its is an
                    important step in personalizing your experience within Hone
                    Connect.
                  </p>
                </div>
              </div>
              <div className="step completed p-[10px] flex flex-row justify-start gap-5">
                <div className="v-stepper relative">
                  {/* <div className="circle"></div>*/}
                  <div className="circle w-[50px] h-[50px] md:w-[80px] md:h-[80px] rounded-[50%] flex justify-center items-center bg-honeConnectPurple">
                    <span className="text-[30px] md:text-[32px] font-bold text-white">
                      3
                    </span>
                  </div>
                </div>
                <div className="content">
                  <h1 className="text-lg md:text-[30px] font-bold mb-2">
                    Browse & Connect
                  </h1>
                  <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                    After creating your profile and setting your preferences,
                    you're ready to browse and connect with others within the
                    Hone Connect.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Frequently Asked Questions Section*/}
      <div className="w-11/12 mx-auto py-10 grid">
        <div className="grid grid-cols-12 items-center">
          <div className="md:order-2 lg:order-1 col-span-12 lg:col-span-8 lg:pr-40">
            <h1 className="text-center lg:text-left text-[25px] md:text-[40px] font-bold mb-10 md:mb-16 text-[#061737]">
              Frequently
              <span className="text-honeConnectPurple"> Asked Questions</span>
            </h1>
            <div className="flex flex-col gap-5">
              <FAQ />
            </div>
          </div>
          <div className="md:order-1 lg:order-2 col-span-12 lg:col-span-4 mx-auto hidden lg:block">
            <img src={FaqImg} alt="" className="h-[400px]" />
          </div>
        </div>
      </div>

      {/* Testimonials Section*/}
      <div className="bg-[#F8FAFC] hidden">
        <div className="w-11/12 lg:w-8/12 mx-auto py-10 grid text-center">
          {/* Heading */}
          <h1 className="text-[25px] md:text-[40px] font-bold mb-10 md:mb-16 text-center">
            Our<span className="text-honeConnectPurple"> Testimonials</span>
          </h1>
          {/* Image Slider */}
          <div className="mb-4 flex items-center gap-2 md:gap-8 lg:gap-14 mx-auto">
            <Link to="/" className="text-honeConnectPurple">
              <LuChevronLeft className="text-[40px]" />
            </Link>
            <Link to="/">
              <img src={testimonialImg1} alt="" className="w-20 rounded-full" />
            </Link>
            <Link to="/">
              <img src={testimonialImg2} alt="" className="w-20 rounded-full" />
            </Link>
            <Link to="/">
              <img
                src={testimonialImg3}
                alt=""
                className="active w-32 rounded-full border-solid border-honeConnectPurple border-[5px]"
              />
            </Link>
            <Link to="/">
              <img src={testimonialImg4} alt="" className="w-20 rounded-full" />
            </Link>
            <Link to="/">
              <img src={testimonialImg5} alt="" className="w-20 rounded-full" />
            </Link>
            <Link to="/" className="text-honeConnectPurple">
              <LuChevronRight className="text-[40px]" />
            </Link>
          </div>
          {/* Slider Caption  */}
          <div className="lg:px-52">
            <p className="mb-10 font-semibold text-sm md:text-lg">Taiwo</p>
            <div className="relative mt-14">
              <div className="triangle-up w-0 h-0 border-solid border-l-[25px] border-r-[25px] border-b-[50px] border-transparent border-b-honeConnectPurple rounded-[4px] absolute left-[46%] top-[-25%]"></div>
              <p className="text-xs md:text-sm font-medium md:font-semibold text-white bg-honeConnectPurple p-10 rounded-[20px]">
                Hone Connect has become a valuable part of my online experience,
                and I wouldn't hesitate to recommend it to others looking for a
                dynamic and enriching platform.
              </p>
            </div>
          </div>
        </div>
      </div>
      <WebsiteFooter />
    </main>
  );
};

export default WebsiteIndex;
