import { LadyLookingAtPhone } from "../assets/img";
import { LayoutProps } from "./common/layout.types";
import GlobalLayout from "./GlobalLayout";

const AuthLayout = ({ children, title = "" }: LayoutProps) => (
  <GlobalLayout title={title}>
    <main className="grid lg:grid-cols-6 w-full lg:w-11/12 xl:w-7/12 mx-auto lg:pt-8 h-[100vh]">
      {/* side image  */}
      <section className="hidden lg:block lg:col-span-3">
        <img
          src={LadyLookingAtPhone}
          alt="Lady Looking At Phone"
          className="w-10/12 lg:w-10/12"
        />
      </section>

      {/* child compoent for auth screens */}
      <section className="p-3 lg:col-span-3">{children}</section>
    </main>
  </GlobalLayout>
);

export default AuthLayout;
