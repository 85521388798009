import React from "react";
import { Link, useLocation } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";

const TopNav = () => {
  const location = useLocation();

  return (
    <nav className="hidden sm:block bg-honeConnectPurple text-white text-xs font-medium">
      <ul className="mx-auto flex container">
        <li
          className={`${
            location.pathname === SITELINKS.HOME && "bg-hc_59077A"
          } border-l border-solid border-white`}
        >
          <Link
            to={SITELINKS.HOME}
            className="inline-block py-4 sm:px-7 md:px-14 lg:px-20"
          >
            Home
          </Link>
        </li>

        <li
          className={`${
            location.pathname === SITELINKS.MAKE_REQUEST && "bg-hc_59077A"
          } border-l border-solid border-white`}
        >
          <Link
            to={SITELINKS.MAKE_REQUEST}
            className="inline-block py-4 sm:px-7 md:px-14 lg:px-20 flex gap-2"
          >
            <span>My Request</span>
          </Link>
        </li>

        <li
          className={`${
            location.pathname === SITELINKS.MY_RESPONSES && "bg-hc_59077A"
          } border-l border-solid border-white`}
        >
          <Link
            to={SITELINKS.MY_RESPONSES}
            className="inline-block py-4 sm:px-7 md:px-14 lg:px-20"
          >
            My Responses
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default TopNav;
