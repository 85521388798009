import { usePaystackPayment } from "react-paystack";
import { getUser, PAYSTACK_PUBLIC_KEY } from "../api-services/process";
import { UserProfileInterface } from "../app-schemas/user.interface";

const PaymentButton: React.FC<{ amount: number; btnLabel: string }> = ({
  amount,
  btnLabel,
}) => {
  const user: UserProfileInterface = getUser();
  const config = {
    reference: new Date().getTime().toString() + user?.id,
    email: user?.email,
    amount: amount * 100,
    publicKey: PAYSTACK_PUBLIC_KEY || "",
  };

  const initializePayment = usePaystackPayment(config);

  const payWithPayStack = () => {
    try {
      initializePayment({
        onSuccess: (reference) => {
          console.log("Payment successful:", reference);
          // Handle success (e.g., update backend, show success message)
        },
        onClose: () => {
          console.log("Payment closed.");
          // Handle close (e.g., notify user that they canceled the payment)
        },
      });
    } catch (error) {
      console.error("Error during payment initialization:", error);
      alert(
        "An error occurred while processing the payment. Please try again."
      );
    }
  };

  return (
    <button
      type="button"
      onClick={payWithPayStack}
      className="paystack-button paystack-button bg-honeConnectPurple text-white w-full p-3 rounded-lg border-0 text-base mt-4 mb-6 font-medium"
    >
      {btnLabel}
    </button>
  );
};

export default PaymentButton;
