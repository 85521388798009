import apiClient from "../../ApiClient";

export const getConnections = async ({
  pageParam = 1,
}: {
  pageParam: number;
}): Promise<any> => {
  const res = await apiClient.get("connection-request", {
    params: {
      page: pageParam,
    },
  });

  return {
    data: res.data.data ?? [],
    totalPages: res.data?.last_page ?? 0,
    totalItems: res.data?.total ?? 0,
    currentPage: res.data?.current_page ?? 0,
  };
};
