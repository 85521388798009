import { LuMapPin } from "react-icons/lu";
import { APP_STORAGE, getUser } from "../api-services/process";
import { UserProfileInterface } from "../app-schemas/user.interface";
import { UserImg } from "../assets/img";

const SuggestionsSideBar = () => {
  const user: UserProfileInterface = getUser();

  return (
    <div className="hidden sm:col-span-4 md:col-span-4 lg:col-span-3 sm:flex flex-col bg-white rounded-10px">
      <div>
        <img src="img/Rectangle 40073.png" alt="moom" />
      </div>

      {/* profile area */}
      <div className="text-center -mt-14 py-6">
        {user.profile_pix_link ? (
          <img
            src={APP_STORAGE + user.profile_pix_link}
            alt="User"
            className="w-16 inline-block rounded-xl border-[1px] border-honeConnectPurple"
          />
        ) : (
          <img
            src={UserImg}
            alt="User"
            className="w-16 inline-block rounded-xl border-[1px] border-honeConnectPurple"
          />
        )}

        <p className="font-semibold text-xl mt-2 capitalize">{user?.username}</p>

        <div className="flex justify-center items-center gap-2 text-honeConnectGray font-normal">
          <div>
            <LuMapPin className="drop-box-shadow text-sm" />
          </div>
          <p className="text-sm">
            {user?.city?.name}, {user?.state?.name}
          </p>
        </div>
      </div>

      <div className="bg-[#e2e2e2]">
        <p className="py-2 px-6 text-honeConnectBlack text-lg font-semibold">
          Suggestions
        </p>
      </div>

      {/* suggested area - sample */}
      <div className="flex flex-col">
        <div className="flex gap-2 items-center py-3 px-4 border-b border-l-white border-l-4 hover:border-l-honeConnectPurple border-solid border-hc[#b1b1b1] bg-honeConnectGray50">
          <div>
            <img src="img/Eddie93.png" className="w-10" alt="Display" />
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-medium text-honeConnectBlack">Eddie93</p>
            <p className="text-xs text-honeConnectGray">
              Lagos, Nigeria
            </p>
          </div>
        </div>

        <div className="flex gap-2 items-center py-3 px-4 border-b border-l-white border-l-4 hover:border-l-honeConnectPurple border-solid border-hc[#b1b1b1] bg-honeConnectGray50">
          <div>
            <img src="img/Agatha444.png" className="w-10" alt="Display" />
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-medium text-honeConnectBlack">
              Agatha44
            </p>
            <p className="text-xs font-medium text-honeConnectGray">
              Lagos, Nigeria
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionsSideBar;
