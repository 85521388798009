// login component
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthLayout from "../../app-layouts/AuthLayout";
import { SITELINKS } from "../../app-routes/links";
import apiClient from "../../api-services/ApiClient";
import { successAlert } from "../../app-util/SweetAlert";
import { storeToken, storeUser } from "../../api-services/process";
import Preloader from "../../app-components/preloader/Preloader";

const VerifyEmail = () => {
  const { email, code } = useParams<{ email: string; code: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (email && code) handleVerification(code ?? "", email ?? "");

    // eslint-disable-next-line
  }, [email, code]);

  // handle-login-submit
  const handleVerification = async (code: string, email: string) => {
    try {
      setLoading(true);
      const data = {
        email: email,
        code: code,
      };

      const res = await apiClient.post("verify-2fa", data);

      if (res.data.status) {
        successAlert();
        const user = res.data?.data?.user;

        storeToken(res.data?.data?.token);
        storeUser(user);
        navigate(SITELINKS.HOME);
      }
    } catch (error: any) {
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <h2 className="text-start p-10 text-red-500">{error}</h2>
      <Preloader loading={loading} />
    </AuthLayout>
  );
};

export default VerifyEmail;
