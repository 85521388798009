import React, { useState, useEffect } from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import MyProfileSideBar from "../../pages/home/myProfileSideBar";
import { UserProfileInterface } from "../../app-schemas/user.interface";
import { APP_STORAGE } from "../../api-services/process";
import { Link, useParams } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { FaChevronCircleLeft } from "react-icons/fa";
import { Group1000004983, IG, Snapchat, UserImg, X, GOLD, SILVER } from "../../assets/img";
import { calculateAge } from "../../app-util/CalculateAge";
import { LuMapPin } from "react-icons/lu";
import { TERipple } from "tw-elements-react";
import apiClient from "../../api-services/ApiClient";
import Preloader from "../../app-components/preloader/Preloader";

const MyProfile = () => {
  const { id } = useParams();
  const [copySuccess, setCopySuccess] = useState<string | null>();
  const [laoding, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<Partial<UserProfileInterface>>({});
  const [gallery, setGallery] = useState<any>([]);

  const getBadgeSrc = (badge: string) => {
    switch (badge) {
      case "GOLD":
        return GOLD;
      case "SILVER":
        return SILVER;
      default:
        return "";
    }
  };
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess(null);
    }
  };

  useEffect(() => {
    if (id) {
      getUserInfo(id);
      getAuthenticatedUserGallery(id);
    }
  }, [id]);

  const getUserInfo = async (id: string) => {
    try {
      setLoading(true);
      const res = await apiClient.get(`user-info/${id}`);
      setUser(res.data);
    } catch (err) {
      setLoading(false);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getAuthenticatedUserGallery = async (id: string) => {
    try {
      const res = await apiClient.get(`user-gallery/${id}`);
      setGallery(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  if (!id) {
    return (
      <AppLayout>
        <p className="mt-30">Cannot load profile, try again later</p>
      </AppLayout>
    );
  }

  if (laoding) {
    return (
      <AppLayout>
        <Preloader loading={true} />
      </AppLayout>
    );
  }

  if (!laoding && !user) {
    return (
      <AppLayout>
        <p className="mt-30">Cannot load profile, try again later</p>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <main className="sm:py-10">
        <div className="grid grid-cols-12 gap-8 justify-between w-full sm:w-11/12 mx-auto">
          <MyProfileSideBar></MyProfileSideBar>

          <div className="col-span-12 md:col-span-8 lg:col-span-7 sm:rounded-10px">
            <div
              style={{ backgroundImage: `url(${Group1000004983})` }}
              className="bg-cover bg-honeConnectPurple bg-center p-6 py-20 sm:rounded-t-[10px] h-[110px] sm:-mb-0"
            >
              <p className="sm:hidden text-sm mb-8 font-semibold hidden">
                <Link to={SITELINKS.HOME} className="text-white">
                  <FaChevronCircleLeft /> Back
                </Link>
              </p>

              <div className="flex gap-4 sm:gap-6 items-center">
                <div className="relative w-2/6">
                  <div className="max-h-[100px] overflow-hidden border-honeConnectPurple border border-2 rounded-lg">
                    {user?.profile_pix_link ? (
                      <img
                        src={APP_STORAGE + user?.profile_pix_link}
                        alt="User"
                        className="w-40"
                      />
                    ) : (
                      <img src={UserImg} alt="User" className="w-40" />
                    )}
                  </div>

                  {user?.badge && (
                    <img
                      src={getBadgeSrc(user?.badge)}
                      alt={`${user?.badge} Badge`}
                      className="w-16 absolute -top-6 -right-6"
                    />
                  )}
                </div>

                <div className="text-white flex-col gap-2 mt-1 sm:mt-10">
                  <p className="font-medium text-lg">
                    {user?.full_name}, {calculateAge(user?.DOB ?? "")}
                  </p>

                  <div className="flex gap-2 text-xs font-normal items-center">
                    <LuMapPin />
                    <p>
                      {user?.city?.name}, {user?.state?.name}{" "}
                      {user?.country?.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* my bio */}
            <div className="bg-white p-6 sm:pt-[100px] pt-10 sm:pt-8">
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-base text-honeConnectBlack">
                  My Bio
                </p>
                <div className="flex flex-col justify-between">
                  <p className="text-honeConnectBlack font-normal text-sm">
                    {user?.bio ?? "Hello, i am available"}
                  </p>
                  <Link
                    to={SITELINKS.EDIT_PROFILE}
                    className="flex-col text-honeConnectPurple font-medium text-sm underline self-start mt-2"
                  >
                    Edit
                  </Link>
                </div>
              </div>
            </div>

            {/* my interest */}
            <div className="bg-white p-6 mt-2">
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-base text-honeConnectBlack">
                  My Interest
                </p>

                <div className="flex-col justify-between lg:gap-28">
                  <div className="flex flex-wrap mb-2 mt-2">
                    <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px]">
                      {!!user && user.interests && user.interests.length > 0
                        ? user.interests[0].interest
                        : ""}
                    </p>
                  </div>

                  <Link
                    to={SITELINKS.EDIT_PROFILE}
                    className="flex-col text-honeConnectPurple font-medium text-sm underline self-start"
                  >
                    Edit
                  </Link>
                </div>
              </div>
            </div>

            {/* social */}
            <div className="bg-white p-8 mt-2">
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-base text-honeConnectBlack">
                  Social Account
                </p>

                <div className="flex flex-wrap justify-between gap-28">
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-10 items-center">
                    <div className="flex gap-2 items-center">
                      <img src={IG} alt="" className="w-10" />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs text-honeConnectGray font-medium">
                          Instagram
                        </p>
                        <p className="text-sm font-normal text-honeConnectBlack">
                          {user?.instagram_id ?? "Instagram not found"}
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2 items-center">
                      <img src={Snapchat} alt="" className="w-10" />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs text-honeConnectGray font-medium">
                          Snapchat
                        </p>
                        <p className="text-sm font-normal text-honeConnectBlack">
                          {user?.snapchat_id ?? "Snapchat not found"}
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2 items-center">
                      <img src={X} alt="" className="w-10" />
                      <div className="flex flex-col gap-1">
                        <p className="text-xs text-honeConnectGray font-medium">
                          X
                        </p>
                        <p className="text-sm font-normal text-honeConnectBlack">
                          {user?.twitter_x_id ?? "X not found"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="hidden xl:block"></div>
                </div>
              </div>
            </div>

            {/* my photos */}
            <div className="bg-white p-8 mt-2">
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-base text-honeConnectBlack">
                  Photos
                </p>

                <div className="flex justify-between gap-28">
                  <div className="flex gap-10 flex-wrap items-center">
                    {gallery.map((item: any, index: number) => {
                      return (
                        <div className="relative border border-honeConnectPurple border-2 rounded-lg p-1">
                          <img
                            src={APP_STORAGE + item.gallery_pix_link}
                            alt=""
                            className="w-28 min-h-[100px] rounded-lg"
                          />
                          {/* <!-- Number in circle --> */}
                          <div className="absolute -top-3 -right-3 w-[30px] h-[30px] border-[3px] border-honeConnectPurple border-solid rounded-[50%] flex justify-center items-center bg-white">
                            <span className="text-sm font-semibold text-honeConnectPurple">
                              {index + 1}
                            </span>
                          </div>
                        </div>
                      );
                    })}

                    <div className="flex flex-col gap-1 hidden">
                      <div className="relative">
                        <a href="/home">
                          <img
                            src="img/pictureUpload.png"
                            alt=""
                            className="w-28"
                          />
                        </a>

                        {/* <!-- Number in circle --> */}
                        <div className="absolute -top-3 -right-3 w-[30px] h-[30px] border-[3px] border-honeConnectPurple border-solid rounded-[50%] flex justify-center items-center bg-white">
                          <span className="text-sm font-semibold text-honeConnectPurple">
                            2
                          </span>
                        </div>
                      </div>
                      <a
                        href="/home"
                        className="text-honeConnectOrange text-xs font-medium"
                      >
                        Upload Photo
                      </a>
                    </div>
                  </div>
                </div>

                <div className="text-left hidden">
                  <Link
                    to={SITELINKS.EDIT_PROFILE}
                    className="text-honeConnectPurple font-medium text-sm underline"
                  >
                    See all Photos
                  </Link>
                </div>
              </div>
            </div>

            {/* <!-- copy link --> */}
            <div className="bg-white p-6 mt-2 pb-36 sm:mb-0 rounded-b-[10px]">
              <div className="flex flex-col gap-1 items-start sm:items-center">
                <p className="text-sm font-medium text-honeConnectGray">
                  Copy link
                </p>
                <div className="relative w-full">
                  <input
                    type="text"
                    className="w-full sm:w-2/4 bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal text-xs"
                    value={"https://honeconnect.com/me/" + user?.username}
                    disabled
                  />

                  <button
                    onClick={() => {
                      copyToClipboard(
                        "https://honeconnect.com/me/" + user?.username
                      );
                    }}
                    className="absolute top-[3px] right-[3px]"
                  >
                    <TERipple className="bg-honeConnectPurple text-gray-100 p-2 rounded">
                      {copySuccess ? (
                        <div className="flex gap-1">Copied</div>
                      ) : (
                        <div className="flex gap-1">Copy</div>
                      )}
                    </TERipple>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <AdsBanner></AdsBanner>
        </div>
      </main>

      <Preloader loading={laoding} />
    </AppLayout>
  );
};

export default MyProfile;
