import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import apiClient from "../../api-services/ApiClient";
import queryString from "query-string";
import Preloader from "../../app-components/preloader/Preloader";
import { errorAlert } from "../../app-util/SweetAlert";
import { storeToken, storeUser } from "../../api-services/process";
import { SITELINKS } from "../../app-routes/links";

const GoogleCallback = () => {
  const navigate = useNavigate();

  const GoogleLogin = async (access_token: any) => {
    try {
      const data = { access_token };
      const res = await apiClient.post("/login-with-google", data);
      const user = res.data?.data?.user;

      storeToken(res.data?.data?.token);
      storeUser(user);

      if (!user?.username || !user?.DOB || !user?.country_id) {
        navigate(SITELINKS.REGISTER_STEP_TWO);
      } else {
        navigate(SITELINKS.HOME);
      }
    } catch (error: any) {
      console.log(error);
      errorAlert();
      navigate(SITELINKS.LOGIN);
    }
  };

  useEffect(() => {
    const { access_token } = queryString.parse(window.location.hash);

    if (access_token) {
      GoogleLogin(access_token);
    } else {
      errorAlert();
      navigate("/login");
    }

    // eslint-disable-next-line
  }, [navigate]);

  return <Preloader loading={true} />;
};

export default GoogleCallback;
