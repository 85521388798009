import React from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import SuggestionsSideBar from "../../app-components/SuggestionsSideBar";

// import SuggestionsSideBar from "./home/SuggestionsSideBar";☻

const PrivateAccount = () => {
  return (
    <AppLayout>
      <main className="sm:py-10">
        <div className="grid grid-cols-12 gap-8 justify-between w-full sm:w-11/12 mx-auto">
          {/* <!-- COlumn 3 --> */}
          <SuggestionsSideBar />
          {/* <!-- COlumn 7 --> */}
          <div className="col-span-12 md:col-span-8 lg:col-span-7 sm:rounded-10px">
            {/* <!-- ROW --> */}
            <div className="sm:bg-white sm:p-3 px-5 rounded-10px -mb-[50px] sm:mb-5 flex justify-between items-center">
              <div className="">
                <a
                  href="#!"
                  className="text-white sm:text-honeConnectPurple font-semibold text-sm"
                >
                  <i className="fa-solid fa-chevron-left"></i> Back
                </a>
              </div>
              <button className="text-sm py-2 px-4 border border-solid bg-hc_F3A838 font-semibold text-white rounded-[5px]">
                + Favorite
              </button>
            </div>
            {/* <!-- ROW 1 --> */}
            <div
              style={{ backgroundImage: `url('img/Group1000004983.png')` }}
              className="bg-cover bg-center p-8 sm:rounded-t-[10px] -mb-[100px] sm:-mb-0"
            >
              <div className="flex gap-4 sm:gap-6 items-center mt-[45px] sm:mt-0">
                <div className="relative">
                  <img src="img/Anonymous.png" alt="" className="w-40" />
                  <img
                    src="img/gold-medal.png"
                    className="w-16 absolute -top-6 -right-6"
                    alt=""
                  />
                </div>
                <div className="text-white flex flex-col gap-2 -mt-[60px] sm:mt-10">
                  <p className="font-semibold text-xl">Barbara Esther, 25</p>
                  <div className="flex items-center gap-2 text-sm font-normal">
                    <i className="fa-solid fa-location-dot"></i>
                    <p>Surulere, Lagos</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-white p-8 pt-[100px] sm:pt-8">
              <div className="flex flex-col gap-6">
                <p className="font-semibold text-base text-honeConnectBlack">
                  My Bio
                </p>
                <a
                  href="#userLocked"
                  className="text-honeConnectPurple font-medium text-2xl underline text-right"
                >
                  <i className="fa-solid fa-user-lock"></i>
                </a>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-white p-8 mt-5">
              <div className="flex flex-col gap-4">
                <p className="font-semibold text-base text-honeConnectBlack">
                  My Interest
                </p>
                <a
                  href="#userLocked"
                  className="text-honeConnectPurple font-medium text-2xl underline text-right"
                >
                  <i className="fa-solid fa-user-lock"></i>
                </a>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-white p-8 mt-5">
              <div className="flex flex-col gap-4">
                <p className="font-semibold text-base text-honeConnectBlack">
                  Social Account
                </p>
                <a
                  href="#userLocked"
                  className="text-honeConnectPurple font-medium text-2xl underline text-right"
                >
                  <i className="fa-solid fa-user-lock"></i>
                </a>
              </div>
            </div>
            {/* <!-- ROW --> */}
            <div className="bg-white p-24 mt-5 rounded-b-[10px] flex flex-col gap-3 items-center">
              <i className="fa-solid fa-lock text-6xl text-honeConnectPurple"></i>
              <p className="text-xs font-medium text-honeConnectGray">
                This account is private
              </p>
            </div>
          </div>
          {/* <!-- Column 2 --> */}
          <AdsBanner></AdsBanner>
        </div>
      </main>
    </AppLayout>
  );
};

export default PrivateAccount;
