import React, { useState, useCallback, useRef } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../app-util/cropImageHelper";
import { CameraIcon } from "../../assets/custom-icons";

interface CropProps {
  onImageSelect: (file: File) => void;
}

const ImageCrop: React.FC<CropProps> = ({ onImageSelect }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => setImageSrc(reader.result as string);
    }
  };

  const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = async () => {
    if (!imageSrc || !croppedAreaPixels) return;

    setIsUploading(true)
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    onImageSelect(croppedImage);

  };

  const handleIconClick = () => {    
    setIsUploading(false)
    fileInputRef.current?.click();
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        accept="image/*"
        className="hidden"
        onChange={handleImageChange}
      />

      {!imageSrc && (
        <div className="flex justify-center mt-2">
          <img
            src={CameraIcon} // Show preview if available, otherwise CameraIcon
            className="w-full p-1 rounded-lg text-center border-2 border-dashed border-honeConnectOrange cursor-pointer"
            onClick={handleIconClick}
            alt="Upload Preview"
          />
        </div>
      )}

      {imageSrc && (
        <>
          <div className="p-1 rounded text-center border-2 border-dashed border-honeConnectOrange cursor-pointer">
            <div
              style={{
                borderRadius: "50px",
                position: "relative",
                width: "100%",
                height: 350,
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          </div>

          <div className="flex gap-4">
            <button
              onClick={handleCropSave}
              disabled={isUploading}
              type="button"
              className="disabled:text-gray-500 disabled:bg-gray-100 text-sm text-honeConnectPurple font-medium p-2 border"
            >
              Use Selection
            </button>

            <button
              onClick={handleIconClick}
              type="button"
              className="text-sm border text-honeConnectOrange font-medium pt-2 border-honeConnectOrange flex gap-2"
            >
              <span> Change Image</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCrop;
