// cropImageHelper.ts
export const getCroppedImg = (imageSrc: string, crop: any): Promise<File> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d")!;
      canvas.width = crop.width;
      canvas.height = crop.height;
      ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob((blob) => {
        const file = new File([blob!], "croppedImage.jpeg", {
          type: "image/jpeg",
        });
        resolve(file);
      }, "image/jpeg");
    };
  });
};
