import React, { useEffect } from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import SuggestionsSideBar from "../../app-components/SuggestionsSideBar";
import { TERipple } from "tw-elements-react";
import { useNotification } from "../../api-services/api-query/notifications/useNotification";
import { APP_STORAGE } from "../../api-services/process";
import apiClient from "../../api-services/ApiClient";
import { useUnreadNotificationCounter } from "../../api-services/api-query/notifications/useUnreadNotificationCounter";

const Notifications = () => {
  const notification = useNotification();
  const unreadNotification = useUnreadNotificationCounter();

  useEffect(() => {
    if (notification.isFetched) {
      const timeout = setTimeout(() => {
        markAllAsRead();
      }, 5000); // Delay for 2 seconds

      // Cleanup the timeout on component unmount or if the effect runs again
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.isFetched]);

  const markAllAsRead = async () => {
    try {
      await apiClient.patch("notifications/mark-all-as-read");
      unreadNotification.refetch();
    } catch (error) {
      console.error("Failed to mark notifications as read:", error);
    }
  };

  return (
    <AppLayout>
      <main className="sm:py-10">
        <div className="grid grid-cols-12 gap-8 justify-between w-full sm:w-11/12 mx-auto h-[100vh]">
          {/* <!-- COlumn 3 --> */}
          <SuggestionsSideBar />
          {/* <!-- COlumn 7 --> */}

          <div className="col-span-12 md:col-span-8 lg:col-span-7 sm:rounded-10px py-6 px-3 pb-28">
            {/* <!-- ROW --> */}
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Notifications
              </p>
            </div>

            {notification?.data?.map((item: any) => {
              return (
                <TERipple rippleColor="indigo" className="mb-3">
                  <div
                    className={`${
                      item?.is_read ? "bg-honeConnectGray50" : "bg-[#FFE2B7]"
                    } border border-solid border-honeConnectGray200 rounded-10px p-3.5 grid grid-cols-12 gap-3`}
                  >
                    <div className="col-span-10 flex gap-3 items-center">
                      <div className="w-3/5 max-h-[100px] overflow-hidden  rounded-10px border border-solid border-honeConnectPurple">
                        <img
                          src={
                            !!item.responder
                              ? APP_STORAGE + item.responder.profile_pix_link
                              : "img/HoneConnect.png"
                          }
                          className="w-full rounded-10px border border-solid border-honeConnectPurple"
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <p className="text-sm font-bold text-honeConnectBlack">
                          {item.type}
                        </p>
                        <p className="text-xs font-normal text-honeConnectGray">
                          {item.message}
                        </p>
                        <p className="text-xs font-medium text-honeConnectBlack">
                          {item.created_at}
                        </p>
                      </div>
                    </div>

                    <div className="col-span-2 justify-self-end">
                      <a href="#cancel">
                        <i className="fa-solid fa-xmark text-2xl text-honeConnectPurple"></i>
                      </a>
                    </div>
                  </div>
                </TERipple>
              );
            })}

            {notification?.data?.length < 1 && (
              <p className="text-center w-full mt-20 text-sm">
                No notification
              </p>
            )}

            {/* <!-- ROW --> */}
            <div className="bg-[#FFE2B7] border border-solid border-honeConnectGray200 rounded-10px p-3.5 grid grid-cols-12 gap-3 mb-5 hidden">
              <div className="col-span-10 flex gap-3 items-center">
                <div>
                  <img
                    src="img/Brenda55.png"
                    className="w-[80px] rounded-10px border border-solid border-honeConnectPurple"
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-sm font-bold text-honeConnectBlack">
                    Brenda745
                  </p>
                  <p className="text-xs font-normal text-honeConnectGray">
                    Has an open request
                  </p>
                  <p className="text-xs font-medium text-honeConnectBlack">
                    15 minutes ago
                  </p>
                </div>
              </div>
              <div className="col-span-2 justify-self-end">
                <a href="#cancel">
                  <i className="fa-solid fa-xmark text-2xl text-honeConnectPurple"></i>
                </a>
              </div>
            </div>
          </div>

          {/* <!-- Column 2 --> */}
          <AdsBanner></AdsBanner>
        </div>
      </main>
    </AppLayout>
  );
};

export default Notifications;
