// login component
import React, { useState, useEffect, FormEvent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "../../app-layouts/AuthLayout";
import { LuChevronLeft } from "react-icons/lu";
import { SITELINKS } from "../../app-routes/links";
import OTPInput from "react-otp-input";
import apiClient from "../../api-services/ApiClient";
import { successAlert } from "../../app-util/SweetAlert";
import { storeToken, storeUser } from "../../api-services/process";

const VerificationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [resendCode, setResendCode] = useState<boolean>(true);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string>("");
  const [timeLeft, setTimeLeft] = useState(180);

  useEffect(() => {
    if (!location.state || !location.state.fromResetPassword) {
      navigate(SITELINKS.LOGIN);
    } else {
      setEmail(location.state.email);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (timeLeft === 0) {
      setResendCode(false);
    }
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  // Format time into MM:SS
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleOTPChange = (otp: any) => {
    setOtp(otp);
  };

  // handle-login-submit
  const handleVerification = async (e: FormEvent) => {
    e.preventDefault();
    const result = otp.length === 6;

    setError("");
    if (!result) {
      setError("code must be 6 digit in length");
      return;
    }

    try {
      setLoading(true);
      const data = {
        email: email,
        code: otp,
      };

      const res = await apiClient.post("verify-2fa", data);

      if (res.data.status) {
        successAlert();
        const user = res.data?.data?.user;

        storeToken(res.data?.data?.token);
        storeUser(user);

        navigate(SITELINKS.UPDATE_PASSWORD);
      }
    } catch (error: any) {
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  // handle-login-submit
  const handleResend2FA = async (e: FormEvent) => {
    e.preventDefault();

    setError("");
    if (!email) {
      setError("Cannot complete process, kindly return to sign in");
      return;
    }

    try {
      setLoading(true);
      setResendCode(true)
      
      await apiClient.post("forgot-password", { email });
      setTimeLeft(180);
    } catch (error: any) {
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <section className="py-3 px-0 lg:col-span-3">
        <div className="text-sm font-semibold">
          <Link
            to={SITELINKS.FORGOT_PASSWORD}
            className="text-honeConnectPurple flex"
          >
            <LuChevronLeft className="text-xl mt-[1px]" /> <span>Back</span>
          </Link>
        </div>

        {/* form section */}
        <div className="flex-col items-center pt-6 lg:pt-20 pb-0 px-3 lg:pr-52 lg:pl-28 lg:px-28 xl:px-28 2xl:px-60 text-honeConnectGray">
          <h4 className="text-xl text-honeConnectBlack font-bold mb-4 text-center">
            Enter Verification Code
          </h4>

          <p className="text-xs mb-8 font-medium text-center">
            A 6-digit code was sent to your email <br />
            <span className="text-honeConnectPurple">{email}</span>
          </p>

          <form
            onSubmit={handleVerification}
            autoComplete="off"
            autoCorrect="off"
          >
            <div className="flex justify-center items-center mb-6 w-full">
              <OTPInput
                value={otp}
                onChange={handleOTPChange}
                numInputs={6}
                shouldAutoFocus
                inputStyle={{
                  width: "2.5rem",
                  height: "2.5rem",
                  margin: "0 10px 0 0",
                  fontSize: "14px",
                  borderRadius: 8,
                  border: "1px solid #6d7986",
                  background: "#f8f8f8",
                }}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            <p className="text-honeConnectOrange text-xs font-medium text-center mb-1">
              Request for new code in {formatTime(timeLeft)}
            </p>

            <div className="text-sm font-medium text-center mb-10 flex-col justify-center">
              <p className="mb-3">Didn’t receive the code?</p>
              <button
                onClick={handleResend2FA}
                type="button"
                disabled={resendCode}
                className="disabled:text-gray-400 disabled:cursor-not-allowed text-honeConnectPurple text-sm font-medium text-center"
              >
                Resend code
              </button>
            </div>

            <p className="text-red-600 text-[12px] text-center mb-4">{error}</p>

            <button
              type="submit"
              disabled={loading}
              className="disabled:bg-gray-600 bg-honeConnectPurple text-white w-full p-3 rounded-lg border-0 text-sm"
            >
              Confirm
            </button>
          </form>
        </div>
      </section>
    </AuthLayout>
  );
};

export default VerificationPage;
